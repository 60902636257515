import React, { ComponentProps, useState } from 'react'
import { View, Text, useDripsyTheme, styled, ScrollView } from 'dripsy'
import { View as NativeView } from 'react-native'
import useFlatlistRowArrows from '@beatgig/hooks/use-flatlist-row-arrows'
import useDiscoverPage from '@beatgig/api-hooks/src/use-discover-page'
import { FacetName } from '@beatgig/api-services'
import { StyleSheet, FlatList } from 'react-native'
import { useCallback } from 'react'
import ArtistSearchCardFast from '@beatgig/ui/artist-search-card-fast'
import useResponsive, { useCalc } from '@beatgig/hooks/use-responsive'
import Cloudinary from '@beatgig/helpers/cloudinary'
import defaultBreakpoints from '@beatgig/theme/breakpoints'
import useCanISeeArtistPrice from '@beatgig/auth-hooks/use-can-i-see-artist-price'
import numeral from 'numeral'
import { useMemo } from 'react'
import DiscoverSectionArtistRowTitle from '@beatgig/ui/discover-section-artist-row/discover-section-artist-row-title'
import DiscoverSectionArrowOverlay from '@beatgig/ui/discover-section-artist-row/discover-section-arrow-overlay'
import { Platform } from 'react-native'
import { DiscoverPage } from '@beatgig/api/discover-page'
import { Unpromisify } from '@beatgig/helpers/types'
import useScrollToTop from '@beatgig/hooks/use-scroll-to-top'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import useAutomaticallyOpenOnboardingPopup from '@beatgig/auth-hooks/use-automatically-open-onboarding-popup'
import useTrackScreen from '@beatgig/analytics/hooks/use-track-screen'
import useLikeArtistFast from '@beatgig/api-hooks/src/use-like-artist-card-fast'
import useOpenAuthPopup from '@beatgig/auth-hooks/use-open-auth-popup'
import useNavigateToSearch from '@beatgig/navigation/hooks/use-navigate-to-search'
import searchState, {
  defaultArtistGenreFacetFilters,
} from '@beatgig/search/hooks/use-global-state'
import WebFooter from '@beatgig/components/web-footer'
import VercelHero from '@beatgig/ui/vercel-hero-new'
import useAmISignedIn from '@beatgig/auth-hooks/use-am-i-signed-in'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import { User } from '@beatgig/api/user'
import useTheme from '@beatgig/theme/use-theme'
import useConsoleLog from '@beatgig/hooks/use-log'
import { ArtistSmall } from '@beatgig/api-services/artist'

const rowExtractor = (_, index: number) => index.toString()

type Props = {
  initialData?: Unpromisify<ReturnType<typeof DiscoverPage.get>> | null
}

type RowType = NonNullable<
  ReturnType<typeof useDiscoverPage>['data']
>['artist_rows'][0]

// const Header = (
//   <StripeHero
//     title="Book an artist from your pocket"
//     subtitle={`${APP_NAME} is the easiest way to book talent for private parties, colleges, bars, weddings & more.`}
//   />
// )

// const Header = <VercelHero />

const shouldLoopInfinitely = Platform.OS === 'web'

function DiscoverScreenFast({ initialData }: Props) {
  const { data: me } = useMyAccount()
  const { data } = useDiscoverPage({
    initialData: initialData ?? undefined,
    // revalidateOnMount: true,
    refreshInterval: shouldLoopInfinitely ? 0 : undefined,
    refreshWhenHidden: shouldLoopInfinitely ? false : undefined,
    revalidateOnFocus: shouldLoopInfinitely ? false : undefined,
    refreshWhenOffline: shouldLoopInfinitely ? false : undefined,
  })
  const { canSeeArtistPrice } = useCanISeeArtistPrice()
  const { amISignedIn } = useAmISignedIn()
  const open = useOpenAuthPopup()
  // we put this all at the top to let us like artists while keeping the cards themselves very nimble
  const { showLikeButton, likedArtistIds, like, unlike } = useLikeArtistFast({
    screen: 'Discover',
  })

  // const canLikeArtists = data
  // const onPressLikeArtist =
  const ref = useScrollToTop<FlatList>()

  const extraData = useMemo(
    () => ({ canSeeArtistPrice, showLikeButton, like, unlike, likedArtistIds }),
    [canSeeArtistPrice, showLikeButton, like, unlike, likedArtistIds]
  )

  // const [, setSearchGenres] = useGlobalGenreFiltersState()
  const { navigate: openSearch } = useNavigateToSearch()

  const onPressSeeMore = useCallback(
    (searchData: RowType['search_data']) => {
      const categoryFilter = searchData?.facets?.find(
        ({ facet_name }) => facet_name === FacetName.CATEGORY_DATA_SUBGENRES
      )
      if (categoryFilter) {
        console.log('[discover-screen-fast][onPressSeeMore]', {
          categoryFilter,
        })
        // setSearchGenres((state) => ({
        //   ...state,
        //   [categoryFilter.facet_name]: categoryFilter.value,
        // }))

        // set the global genre state, based on what we just selected
        searchState.setGlobalState('genreFilters', (state) => ({
          // default the original filters here to be empty, resetting them
          // ...Object.keys(state).reduce(
          //   (acc, key) => ({ ...acc, [key]: [] }),
          //   {}
          // ),
          ...defaultArtistGenreFacetFilters,
          [categoryFilter.facet_name]: categoryFilter.value,
        }))
        // reset the text query when you search
        searchState.setGlobalState('query', '')
        openSearch()
      }
    },
    [openSearch]
  )

  useTrackScreen({ name: 'Discover (Fast)' })
  useAutomaticallyOpenOnboardingPopup()
  const renderItem = useCallback(
    ({ item: row, index }: { item: RowType; index: number }) => {
      return (
        <Row
          {...row}
          key={row.title}
          canSeeArtistPrice={canSeeArtistPrice}
          showLikeButton={showLikeButton}
          onLike={like}
          onUnlike={unlike}
          onPressSeeMore={onPressSeeMore}
          likedArtistIds={likedArtistIds ?? undefined}
          shouldLoopInfinitely={shouldLoopInfinitely}
          rowIndex={index}
        />
      )
    },
    [
      canSeeArtistPrice,
      like,
      likedArtistIds,
      onPressSeeMore,
      showLikeButton,
      unlike,
    ]
  )
  const { top } = useSafeAreaInsets()
  const { vh } = useResponsive()

  const renderNativeFlatList = useCallback(() => {
    return (
      <FlatList
        keyExtractor={rowExtractor}
        renderItem={renderItem}
        data={data?.artist_rows}
        ref={ref}
        extraData={extraData}
      />
    )
  }, [data?.artist_rows, extraData, ref, renderItem])

  const renderSlowList = useCallback(() => {
    // TODO add sectionlist on native
    // FlatList messes up scroll on web, so we use this instead in a ScrollView
    return (
      <View sx={{ minHeight: vh(100) }}>
        {data?.artist_rows.map((item, index) => (
          <React.Fragment key={rowExtractor(item, index)}>
            {renderItem({ item, index })}
          </React.Fragment>
        ))}
      </View>
    )
  }, [data, renderItem, vh])

  const Wrapper = Platform.select({
    web: View,
    default: ScrollView,
  })
  const wrapperProps = Platform.select({
    web: {
      sx: {
        flex: 1,
        bg: 'background',
        // overflow: 'hidden!important',
        // height: 'auto',
        // touchAction: 'auto',
        // minHeight: vh(100),
      },
      // scrollable: false,
    },
    default: { ref: ref as any },
  })

  return (
    <View sx={{ flex: 1, bg: 'background' }}>
      <VercelHero
        isSignedIn={amISignedIn}
        isApprovedUser={User.isApproved(me)}
        hasOnboarded={User.hasOnboarded(me)}
        onSignUp={open}
        onLogin={open}
      />
      {Platform.select({
        web: renderSlowList(),
        default: renderNativeFlatList(),
      })}
      <WebFooter />
    </View>
  )

  // return (
  //   <View
  //     sx={{
  //       flex: 1,
  //       minHeight: Platform.select({ default: undefined, web: '100vh' }),
  //       bg: 'backgroundLight',
  //     }}
  //   >
  //     {/* <View sx={{ flex: 1 }}> */}
  //     <View
  //       // scrollEventThrottle={16}
  //       sx={{
  //         flex: 1,
  //         // overflow: 'hidden',
  //         // pt: top,
  //         // height: Platform.select({ web: vh(100), default: undefined }),
  //       }}
  //       // nestedScrollEnabled
  //       // ref={ref}
  //     >
  //       {/* <WebHeaderSpacer /> */}
  //       {/* <StripeHero
  //       title="Book an artist for your event"
  //       subtitle="Beatgig is the easiest way to book talent for private parties, colleges, bars, weddings & more."
  //     /> */}
  //       {/* {Header} */}
  //       {renderSlowList()}
  //       {/* <FlatList
  //       ListHeaderComponent={Platform.select({
  //         // web: Header,
  //         default: undefined,
  //       })}
  //       keyExtractor={rowExtractor}
  //       renderItem={renderItem}
  //       data={data}
  //       // ref={ref}
  //       extraData={extraData}
  //       // scrollEnabled={Platform.OS !== 'web'}
  //       // style={styles.container}
  //       contentContainerStyle={Platform.select({
  //         web: undefined,
  //         default: {
  //           overflow: 'hidden!important',
  //         },
  //       })}
  //     /> */}
  //     </View>
  //     {/* </View> */}
  //   </View>
  // )
}

const Row = React.memo(function Row(
  props: RowType & {
    canSeeArtistPrice: boolean
    showLikeButton: boolean
    onLike: ComponentProps<typeof ArtistSearchCardFast>['onLike']
    onUnlike: ComponentProps<typeof ArtistSearchCardFast>['onUnlike']
    likedArtistIds?: string[]
    onPressSeeMore: ComponentProps<
      typeof DiscoverSectionArtistRowTitle
    >['onPressSeeMore']
    shouldLoopInfinitely?: boolean
    rowIndex: number
  }
) {
  const {
    should_render_reviews,
    title,
    artists,
    canSeeArtistPrice,
    showLikeButton,
    onLike,
    onUnlike,
    likedArtistIds,
    onPressSeeMore,
    search_data,
    shouldLoopInfinitely,
    rowIndex,
  } = props
  const { space } = useTheme()
  const { vw } = useResponsive()
  const { calc, divide } = useCalc()
  const aspectRatio = 110 / 150
  const itemWidth = vw(20)
  const itemHeight = calc(divide(itemWidth, `${aspectRatio}`))
  // const itemMinWidth = 150
  const itemMinWidth = 135 + space?.[2]
  const itemMinHeight = itemMinWidth / aspectRatio
  const openAuthPopup = useOpenAuthPopup()

  const {
    flatlist,
    onViewableItemsChanged,
    viewabilityConfig,
    finalData,
    ...overlay
  } = useFlatlistRowArrows({
    data: artists,
    shouldLoopInfinitely,
  })

  const renderItem = useCallback(
    ({
      item: artist,
      index,
    }: {
      item: RowType['artists'][number]
      index: number
    }) => {
      if (!artist) return null
      const imageUrl = Cloudinary(artist.profile_image, {
        width: defaultBreakpoints[0],
        quality: 60,
      })
      // if (!imageUrl) return null
      const formatPrice = (price?: number) =>
        price ? numeral(price).format('0,[0].[0]a') : undefined

      let smallPrice: string | undefined
      if (canSeeArtistPrice) {
        if (artist.display_price?.low) {
          smallPrice = `$${formatPrice(artist.display_price?.low)}`
        }
      }
      let price = smallPrice

      if (price && artist.display_price?.high) {
        price = price + ` - $${formatPrice(artist.display_price?.high)}`
      }
      const rating = artist.reviews_data?.rating
        ? numeral(artist.reviews_data?.rating).format('0.[0]')
        : undefined
      const featuredReview = should_render_reviews
        ? artist?.reviews_data?.top_reviews?.[0]
        : undefined
      if (!index) {
        // console.log('[render-artist-discover-screen-card]', {
        //   itemWidth,
        //   itemHeight,
        //   itemMinWidth,
        //   itemMinHeight,
        // })
      }
      const id = artist.id?.split('-loop')?.filter(Boolean)?.[0]

      const isFirstRow = rowIndex === 0
      const isEarlyInRow = index < 6
      const priorityImageLoad = !!(isFirstRow && isEarlyInRow)

      return (
        <ArtistSearchCardFast
          showArtistPrice={canSeeArtistPrice}
          imageUrl={imageUrl}
          showLikeButton={showLikeButton}
          name={artist.name}
          // height={itemHeight}
          // fix native bug: minHeight doesn't supercede height??
          height={Platform.select({
            web: itemHeight as any,
            default: Math.max(itemHeight as any, itemMinHeight),
          })}
          // fix native bug: minWidth doesn't supercede width??
          width={Platform.select({
            web: itemWidth as any,
            default: Math.max(itemWidth as any, itemMinWidth),
          })}
          // sizes={`(max-width: ${
          //   itemMinWidth * 3
          // }px) ${itemMinWidth}px, ${itemWidth}`}
          sizes={`(max-width: 100vw) ${itemWidth}, ${itemWidth}`}
          id={id}
          slug={artist.slug}
          price={price}
          onLike={onLike}
          onUnlike={onUnlike}
          onPressPrice={canSeeArtistPrice ? undefined : openAuthPopup}
          rating={rating}
          minWidth={itemMinWidth}
          minHeight={itemMinHeight}
          reviewCount={artist.reviews_data?.review_count}
          cloudinaryId={artist.profile_image?.public_cloudinary_id}
          layout="fill"
          priority={priorityImageLoad}
          hasLiked={!!likedArtistIds?.includes(id)}
          smallPrice={smallPrice && smallPrice + '+'}
        />
      )
    },
    [
      canSeeArtistPrice,
      itemHeight,
      itemMinHeight,
      itemMinWidth,
      itemWidth,
      likedArtistIds,
      onLike,
      onUnlike,
      openAuthPopup,
      rowIndex,
      should_render_reviews,
      showLikeButton,
    ]
  )
  const extraData = useMemo(
    () => ({
      canSeeArtistPrice,
      itemHeight,
      itemMinHeight,
      itemWidth,
      space,
      should_render_reviews,
      showLikeButton,
      onLike,
      onUnlike,
      likedArtistIds,
      rowIndex,
    }),
    [
      canSeeArtistPrice,
      itemHeight,
      itemMinHeight,
      itemWidth,
      space,
      should_render_reviews,
      showLikeButton,
      onLike,
      onUnlike,
      likedArtistIds,
      rowIndex,
    ]
  )
  return (
    <NativeView style={styles.item}>
      <DiscoverSectionArtistRowTitle
        searchData={search_data}
        onPressSeeMore={onPressSeeMore}
      >
        {title}
      </DiscoverSectionArtistRowTitle>
      <NativeView style={styles.rowContainer}>
        <RowHeaderComponent />
        <FlatList
          ref={flatlist as any}
          onViewableItemsChanged={onViewableItemsChanged}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          horizontal
          // TODO do this when we're doing native
          // initialNumToRender={Platform.select({
          //   web: undefined,
          //   default: 3,
          // })}
          // data={artists}
          data={finalData}
          showsHorizontalScrollIndicator={false}
          style={styles.list}
          extraData={extraData}
          // ListHeaderComponent={RowHeaderComponent}
          ItemSeparatorComponent={ItemSeparator}
          viewabilityConfig={viewabilityConfig}
          ListFooterComponent={RowHeaderComponent}
        />
        <DiscoverSectionArrowOverlay
          shouldScrollBackToStartAtEnd
          {...overlay}
        />
      </NativeView>
    </NativeView>
  )
})

const viewabilityConfig = {}

const rowHeader = styled(NativeView)({
  pl: [2, 5],
})

const RowHeaderComponent = React.memo(rowHeader)
const ItemSeparator = React.memo(
  styled(NativeView)({
    // pr: (theme) => [theme.space[2] / 2 + 0.00001, null, 1],
    pr: 1,
  })
)

const keyExtractor = (item: ArtistSmall) => item.id

const styles = StyleSheet.create({
  item: {
    marginVertical: 8,
  },
  list: {
    // flex: 1,
    // paddingLeft: 30,
  },
  container: {
    flex: 1,
  },
  rowContainer: {
    flexDirection: 'row',
  },
  webContainer: {
    overflow: 'hidden',
  },
})

export const getStaticProps = async (): Promise<{
  props: Props
  revalidate: number
}> => {
  console.log('[static.get-static-props]', 'will fetch')

  const initialData = (await DiscoverPage.get()) ?? null
  return {
    props: {
      initialData,
    },
    revalidate: 1,
  }
}

export default React.memo(DiscoverScreenFast)
