import React from 'react'
import DiscoverScreen from '@beatgig/screens/discover-screen-fast'
import SEO from '@beatgig/components/seo'
import { createStackNavigator } from '@react-navigation/stack'

const { Navigator, Screen } = createStackNavigator()

function DiscoverPageFast() {
  return (
    <>
      {/* <SEO /> */}
      <Navigator
        screenOptions={{
          cardStyle: {
            flex: 1,
          },
          headerShown: false,
        }}
      >
        {/* <Screen name="Discover">{() => <DiscoverScreen {...props} />}</Screen> */}
        <Screen name="Discover" component={DiscoverScreen} />
      </Navigator>
    </>
  )
}

export default React.memo(DiscoverPageFast)

// export const getStaticProps = Home.getStaticProps
