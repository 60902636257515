import { User } from '@beatgig/api/user'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import useAuth from '@beatgig/auth-hooks/use-auth'
import useOpenAuthPopup from '@beatgig/auth-hooks/use-open-auth-popup'
import useGetMyLikedArtistIds from './use-get-liked-artist-ids'
import useLikeArtistMemo from './use-like-artist-memo'
import { useCallback, ComponentProps } from 'react'
import ArtistSearchCardFast from '@beatgig/ui/artist-search-card-fast'
import Analytics, { LikeArtistAnalyticsProps } from '@beatgig/analytics/typez'

type Props = Pick<LikeArtistAnalyticsProps, 'screen'>

export default function useLikeArtistFast({ screen }: Props) {
  const { data: account, error } = useMyAccount()
  const { loading, user } = useAuth()
  const {
    data: likedArtistIds,
    error: likedArtistIdsError,
  } = useGetMyLikedArtistIds()

  let showLikeButton = true
  const loadingAccount = !account && !error
  const loadingLikes = !likedArtistIds && !likedArtistIdsError
  if (loading || (user && loadingAccount) || (user && loadingLikes)) {
    showLikeButton = false
  }

  const openAuthPopup = useOpenAuthPopup()
  const { like: onLike, unlike: onUnlike } = useLikeArtistMemo({
    onPressWhileUnauthenticated: openAuthPopup,
    onSuccess: () => {
      if (user?.uid) User.refetchMyLikedArtistsSWR(user.uid)
    },
  })
  const like = useCallback<
    ComponentProps<typeof ArtistSearchCardFast>['onLike']
  >(
    ({ artistId, slug }) => {
      Analytics.track('Like Artist', { slug, screen })
      return onLike({ artistId })
    },
    [onLike, screen]
  )
  const unlike = useCallback<
    ComponentProps<typeof ArtistSearchCardFast>['onLike']
  >(
    ({ artistId, slug }) => {
      Analytics.track('Unlike Artist', { slug, screen })
      return onUnlike({ artistId })
    },
    [onUnlike, screen]
  )

  return {
    showLikeButton,
    like,
    unlike,
    likedArtistIds,
  }
}
