import type { ImageLoaderProps } from 'next/image'
import Cloudinary from '.'

type DefaultImageLoaderProps = ImageLoaderProps

function normalizeSrc(src: string): string {
  return src[0] === '/' ? src.slice(1) : src
}

export default function cloudinaryLoader({
  src,
  width,
  quality = 75,
  cloudinaryProps,
}: DefaultImageLoaderProps & {
  cloudinaryProps?: Parameters<typeof Cloudinary>[1]
}): string {
  if (src) {
    // console.log('[cloudinary-loader]', { width, quality })
    return Cloudinary(
      {
        public_cloudinary_id: src,
      },
      {
        width,
        quality,
        ...cloudinaryProps,
      }
    ) as string
  }
  return src
  //   console.log('[cloudinary-loader]', { root, src, width })
  //   // Demo: https://res.cloudinary.com/demo/image/upload/w_300,c_limit,q_auto/turtles.jpg
  //   const params = ['f_auto', 'c_limit', 'w_' + width, 'q_' + (quality || 'auto')]
  //   const paramsString = params.join(',') + '/'
  //   return `${root}${paramsString}${normalizeSrc(src)}`
}

//   function defaultLoader({
//     root,
//     src,
//     width,
//     quality,
//   }: DefaultImageLoaderProps): string {
//     if (process.env.NODE_ENV !== 'production') {
//       const missingValues = []

//       // these should always be provided but make sure they are
//       if (!src) missingValues.push('src')
//       if (!width) missingValues.push('width')

//       if (missingValues.length > 0) {
//         throw new Error(
//           `Next Image Optimization requires ${missingValues.join(
//             ', '
//           )} to be provided. Make sure you pass them as props to the \`next/image\` component. Received: ${JSON.stringify(
//             { src, width, quality }
//           )}`
//         )
//       }

//       if (src.startsWith('//')) {
//         throw new Error(
//           `Failed to parse src "${src}" on \`next/image\`, protocol-relative URL (//) must be changed to an absolute URL (http:// or https://)`
//         )
//       }

//       if (!src.startsWith('/') && configDomains) {
//         let parsedSrc: URL
//         try {
//           parsedSrc = new URL(src)
//         } catch (err) {
//           console.error(err)
//           throw new Error(
//             `Failed to parse src "${src}" on \`next/image\`, if using relative image it must start with a leading slash "/" or be an absolute URL (http:// or https://)`
//           )
//         }

//         if (!configDomains.includes(parsedSrc.hostname)) {
//           throw new Error(
//             `Invalid src prop (${src}) on \`next/image\`, hostname "${parsedSrc.hostname}" is not configured under images in your \`next.config.js\`\n` +
//               `See more info: https://err.sh/next.js/next-image-unconfigured-host`
//           )
//         }
//       }
//     }

//     return `${root}?url=${encodeURIComponent(src)}&w=${width}&q=${quality || 75}`
//   }
