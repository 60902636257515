import { Text, useDripsyTheme, View } from 'dripsy'
import React from 'react'
import Ionicons from '@expo/vector-icons/build/Ionicons'
import type { SxStyleProp } from '@theme-ui/core'
import { DripsyTheme } from '@beatgig/theme'
import numeral from 'numeral'
import Press from '../press'

type Props = {
  rating?: number
  size?: number
  color?: string
  reviewCount?: number
  showReviewCount?: boolean
  ratingSide?: 'left' | 'right'
  sx?: SxStyleProp
  textSx?: SxStyleProp
  starMarginX?: number[] | number | ((theme: DripsyTheme) => number | number[])
  truncated?: boolean
  countColor?: string
  /**
   * TODO deprecate in favor of  `showReviewCount`
   */
  showCount?: boolean
  debug?: boolean
  showRating?: boolean
  ratingColor?: string
  onPressStar?: (rating: 1 | 2 | 3 | 4 | 5) => void
  animated?: boolean
  format?: string
}

export default function StarRating(props: Props) {
  const {
    rating,
    color = 'primary',
    size = 20,
    reviewCount,
    showReviewCount = !!reviewCount,
    textSx = {},
    starMarginX = 1,
    truncated = false,
    countColor = 'text',
    sx = {},
    showCount = true,
    showRating = false,
    ratingColor = 'text',
    onPressStar,
    animated,
    format = '0.0[0]',
  } = props
  const { colors } = useDripsyTheme().theme
  const starColor = (colors?.[color] as string) ?? color

  if (rating == null) return null
  const formattedRating = numeral(rating).format(format)

  if (truncated) {
    return (
      <View sx={{ flexDirection: 'row', alignItems: 'center', ...sx }}>
        <Ionicons
          color={(colors?.[color] as string | undefined) ?? color}
          size={size}
          name={'ios-star'}
        />
        <View sx={{ ml: 1 }}>
          <Text sx={{ color: `text`, fontWeight: '600', ...textSx }}>
            {formattedRating}
            {!!reviewCount && showCount && (
              <Text sx={{ color: countColor, fontWeight: '500' }}>
                {' '}
                ({reviewCount})
              </Text>
            )}
          </Text>
        </View>
      </View>
    )
  }

  const ratingCeiling = 5

  return (
    <View sx={{ flexDirection: 'row', alignItems: 'center', ...sx }}>
      {new Array(ratingCeiling).fill('').map((_, i) => {
        const nthStar = i + 1
        let star: 'star' | 'star-half' | 'star-outline' = `star`
        const ratingPadding = 0.25
        if (rating > nthStar - ratingPadding) {
          star = `star`
        } else if (rating > nthStar - 1 && rating <= nthStar - ratingPadding) {
          star = `star-half`
        } else {
          star = `star-outline`
        }
        const isFirst = i === 0
        const isLast = i === ratingCeiling - 1
        return (
          <Press
            sx={{
              // mx: starMarginX as number | number[],
              ml: isFirst ? 0 : (starMarginX as number),
              mr: isLast ? 0 : (starMarginX as number),
              animationKeyframes: animated
                ? {
                    from: {
                      opacity: 0,
                      transform: [{ translateX: -5 }],
                    },
                    to: { opacity: 1, transform: [{ translateX: 0 }] },
                  }
                : undefined,
              animationFillMode: 'both',
              animationDelay: `${0.05 * i}s`,
              animationDuration: '0.25s',
            }}
            onPress={
              onPressStar ? () => onPressStar(nthStar as any) : undefined
            }
            key={i.toString()}
          >
            <Ionicons name={star} size={size} color={starColor} />
          </Press>
        )
      })}
      {(!!(showReviewCount && !!reviewCount) || showRating) && (
        <View sx={{ ml: 1 }}>
          <Text sx={{ color: countColor, ...textSx }}>
            {showRating && (
              <Text sx={{ fontWeight: 'bold', color: ratingColor }}>
                {formattedRating + ' '}
              </Text>
            )}
            {!!reviewCount && showReviewCount && `(${reviewCount})`}
          </Text>
        </View>
      )}
    </View>
  )
}
