import React, { useMemo } from 'react'
import { View, Text } from 'dripsy'
import { Platform } from 'react-native'
import Logo from '../logo'
import Link from '@beatgig/design/components/link'
import NextLink from 'next/link'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import { AuthedRoutes } from '@beatgig/navigation/routes/authed-routes'
import SocialLink from '../social-link'
import { User } from '@beatgig/api/user'
import Spacer from '@beatgig/design/components/spacer'
import { LEGAL_NAME } from '@beatgig/constants'
import useIntercom from '@beatgig/hooks/use-intercom'
import PublicRoutes from '@beatgig/navigation/routes/public-routes'
import useOpenAuthPopup from '@beatgig/auth-hooks/use-open-auth-popup'
import { SHOW_MARKETING_PAGES } from '@beatgig/constants/feature-flags'

type Props = {
  linkLists?: { [key: string]: { links: Link[] } }
} & React.ComponentProps<typeof View>

type Link = {
  label: string
  local?: boolean
  href?: string
  // props?: Partial<React.ComponentProps<typeof NextLink>>
  onPress?: () => void
}

const empty = {}

const isRow = [false, true]
function WebFooter(props: Props) {
  const { sx = {}, linkLists = empty } = props

  const { data: user } = useMyAccount()

  const renderSocialLinks = useMemo(() => {
    const links = {
      instagram: 'https://instagram.com/beatgig',
      twitter: 'https://twitter.com/Beat_Gig/',
      // youtube: 'https://www.youtube.com/watch?v=3hwJpG-IxwM',
      facebook: 'https://www.facebook.com/beatgig/',
      linkedin: 'https://www.linkedin.com/company/beatgig',
    }

    return Object.entries(links).map(([socialNetwork, url], i) => (
      <View key={url} sx={{ mr: i < Object.keys(links).length ? 3 : 0 }}>
        <SocialLink socialNetwork={socialNetwork} url={url} />
      </View>
    ))
  }, [])

  const { show } = useIntercom()
  const openAuth = useOpenAuthPopup()

  const links = useMemo(() => {
    const links: Props['linkLists'] = {
      ...linkLists,
      Company: {
        links: [
          {
            label: 'Contact',
            onPress: () => {
              show()
            },
          },
          {
            href: '/policies/privacy',
            label: 'Privacy Policy',
          },
          {
            href: '/policies/terms',
            label: 'Terms of Service',
          },
          {
            href: '/policies/cookies',
            label: 'Cookie Policy',
          },
        ],
      },
    }
    const userLinks: { label: string; href: string }[] = [
      {
        label: 'Bookings',
        href: AuthedRoutes.bookings().web.path,
      },
      {
        label: 'Likes',
        href: AuthedRoutes.likes().web.path,
      },
      {
        label: 'Profile',
        href: AuthedRoutes.account().web.path,
      },
    ]
    if (User.isMultiVenueBuyer(user)) {
      userLinks.push({
        label: 'Venues',
        href: AuthedRoutes.venues().web.path,
      })
    }
    if (User.hasOnboarded(user)) {
      links[user.name] = {
        links: userLinks,
      }
    } else {
      if (SHOW_MARKETING_PAGES) {
        links.Products = {
          links: [
            {
              label: 'Colleges',
              href: PublicRoutes.marketingPage('colleges').web.path,
            },
            {
              label: 'Venues',
              href: PublicRoutes.marketingPage('venues').web.path,
            },
            {
              label: 'Artists',
              href: PublicRoutes.marketingPage('artists').web.path,
            },
          ],
        }
      }
      links.Account = {
        links: [
          // ...userLinks,
          {
            label: 'Sign Up',
            onPress: openAuth,
          },
          {
            label: 'Log In',
            onPress: openAuth,
          },
        ],
      }
    }
    return links
  }, [linkLists, openAuth, show, user])

  const renderLogo = useMemo(
    () => (
      <NextLink href="/" passHref prefetch={false}>
        <Logo
          accessibilityRole="link"
          shouldShowLarge={[true]}
          containerSx={{
            pr: isRow.map((row) => (row ? 5 : 0)),
            pb: isRow.map((row) => (row ? 0 : 4)),
            // flexBasis: isRow.map((row) => (row ? '25%' : null)),
            width: ['100%', '20%', null, '20%'],
          }}
        />
      </NextLink>
    ),
    []
  )

  if (Platform.OS !== 'web') return null

  return (
    <View>
      <View sx={{ py: 5, bg: 'muted', mt: 4, ...sx }}>
        <View sx={{ px: [3, 4, 5] }}>
          <View>
            <View
              sx={{
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              {renderLogo}
              {Object.entries(links)?.map(([title, { links }]) => {
                if (!links?.length) return null
                return (
                  <View
                    sx={{
                      width: ['50%', '25%', null, '20%'],
                      px: isRow.map((row) => (row ? 3 : 0)),
                      pt: isRow.map((row) => (row ? 0 : 4)),
                    }}
                    key={title}
                  >
                    <Text
                      variant="caps"
                      sx={{
                        fontWeight: 'bold',
                        mb: 2,
                        color: 'mutedText',
                      }}
                    >
                      {title}
                    </Text>
                    {links?.map(({ href, label, onPress }) => {
                      const child = (
                        <Text
                          accessibilityRole="link"
                          onPress={onPress}
                          sx={{ fontSize: [3, 5] }}
                        >
                          {label}
                        </Text>
                      )
                      return !(href || onPress) ? null : (
                        <View sx={{ mb: 2 }} key={`${href}+${label}`}>
                          {href ? (
                            <NextLink passHref as={href} href={href}>
                              {child}
                            </NextLink>
                          ) : (
                            child
                          )}
                        </View>
                      )
                    })}
                  </View>
                )
              })}
              <View
                sx={{
                  width: ['50%', '25%', null, '20%'],
                  pt: isRow.map((row) => (row ? 0 : 4)),
                }}
              >
                <Text
                  variant="caps"
                  sx={{
                    fontWeight: 'bold',
                    mb: 2,
                    color: 'mutedText',
                  }}
                >
                  Social
                </Text>
                <View sx={{ flexDirection: 'row' }}>{renderSocialLinks}</View>
                <Spacer />
                <Text sx={{ color: 'mutedText' }}>
                  © {new Date().getFullYear()} {LEGAL_NAME}. All rights
                  reserved.
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

WebFooter.defaultProps = {
  // linkLists: [
  //   {
  //     title: 'Company',
  //     links: [
  //       {
  //         href: '/',
  //         label: 'Privacy Policy',
  //       },
  //       {
  //         href: '/',
  //         label: 'Terms of Service',
  //       },
  //       {
  //         href: '/',
  //         label: 'Cookie Policy',
  //       },
  //     ],
  //   },
  // ],
  // linkLists: {
  //   hi: [{ href: '', label: '', local: true}]
  // }
} as Props

export default React.memo(WebFooter)
