import React from 'react'
import HomepageDevices from '../homepage-devices'
import WebHeaderSpacer from '@beatgig/components/web-header/spacer'
import { View as RnView, StyleSheet, Platform } from 'react-native'
// import { css } from '@theme-ui/core'
import Spacer from '@beatgig/design/components/spacer'
import Button from '@beatgig/components/button'
// import RadialGradientBG from '../radial-gradient-bg'
import GradientText from '@beatgig/components/gradient-text'
import View from '@beatgig/components/native-view'
import Text from '@beatgig/components/native-text'
// import { keyframes } from '@emotion/react'
import useWhyDidYouUpdate from '@beatgig/hooks/use-why-did-you-update'
import { AnimatePresence, motion } from 'framer-motion'
import { VercelHeroProps } from './types'
import Sizer from '@beatgig/components/sizer'

const H1 = React.createElement('h1')

const enterUp = {
  from: {
    opacity: 0,
    transform: [{ translateY: 50 }],
  },
  to: {
    opacity: 1,
    transform: [{ translateY: 0 }],
  },
}

const fadeIn = {
  from: {
    opacity: 0,
    transform: [
      {
        scale: 0.95,
      },
    ],
  },
  to: {
    opacity: 1,
    transform: [{ scale: 1 }],
  },
}

const animationDelays = [0.2, 0.35, 0.5, 0.85].map((delay) => delay + 0.15)

const fontSize = [8, 10, 12, 13, 90]
const lineHeight = fontSize.map((l) => l - 1)

const space = (
  <>
    <Spacer height={[2, 3]} />
    <Spacer height={4} />
  </>
)

const enterAnimation = ({
  delay = 0,
  keyframes = enterUp,
}: {
  delay?: number
  keyframes?: typeof enterUp | typeof fadeIn
}): object =>
  Platform.select({
    web: {
      // opacity: [null, null, 0],
      // animation: [null, null, `${keyframes} ease .8s ${delay}s both`],
      // animationDelay: `${delay}s`,
      // animationDuration: '.8s',
      // animationFillMode: 'both',
      // animationKeyframes: keyframes,
    },
    default: {},
  })

export default React.memo(function VercelHero(props: VercelHeroProps) {
  const {
    sx = {},
    onSignUp,
    onLogin,
    onContactSupport,
    isApprovedUser,
    hasOnboarded,
    isSignedIn,
  } = props

  useWhyDidYouUpdate('[main hero]', props)

  return (
    <>
      <>
        <View
          sx={{
            overflow: 'hidden',
            bg: 'background',
            ...sx,
          }}
        >
          {/* <RadialGradientBG sx={{ display: ['flex', null, 'none'] }} /> */}
          <WebHeaderSpacer bg="muted" />
          {space}
          <View sx={{ flexDirection: ['column', null, 'row'] }}>
            <View
              sx={{
                //   maxWidth: 900,
                alignSelf: 'center',
                alignItems: 'center',
                px: 3,
                flex: [null, null, 0.5, 1, 1],
                // flexBasis: [null, null, null, '0%'],
              }}
            >
              <View
                sx={{
                  maxWidth: 700,
                  width: ['100%', null, null, `calc(100% - 60px)`],
                }}
              >
                <>
                  <RnView
                    style={{
                      ...enterAnimation({ delay: animationDelays[0] }),
                    }}
                  >
                    <GradientText
                      sx={{
                        variant: 'text.h1',
                        fontSize: [8, 9, 10, 12, 13],
                        // textAlign: 'center',
                        // lineHeight,
                        color: 'text',
                        marginBottom: 0,
                        // lineHeight: Platform.select({
                        //   web: '1.2em',
                        //   default: undefined,
                        // }),
                        fontFamily: (theme) => theme.customFonts.circ[700],
                        textAlign: ['center', null, 'left'],
                        lineHeight: '1.2em',
                        letterSpacing: '-.03em',
                      }}
                      animated
                      //   as={H1 as any}
                      colors={['#ff4d4d', 'primary']}
                    >
                      Start booking artists.
                    </GradientText>
                  </RnView>
                  <Spacer height={[2, 3]} />
                  <RnView
                    style={{
                      ...enterAnimation({ delay: animationDelays[1] }),
                    }}
                  >
                    <Text
                      sx={{
                        fontSize: [5, 6, 7],
                        // color: 'mutedText',
                        // textAlign: 'center',
                        color: 'mutedText',
                        textAlign: ['center', null, 'left'],
                      }}
                    >
                      BeatGig is the easiest way to book talent for private
                      parties, colleges, bars, weddings & more.
                    </Text>
                  </RnView>
                  <Spacer height={[3, 4]} />
                  <RnView
                    style={{
                      ...enterAnimation({
                        delay: animationDelays[2],
                        keyframes: fadeIn,
                      }),
                    }}
                  >
                    <View
                      sx={{
                        flexDirection: 'row',
                        justifyContent: ['center', null, 'flex-start'],
                      }}
                    >
                      {!isSignedIn && (
                        <motion.div
                          exit={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          // layout
                        >
                          <Button onPress={onSignUp} sx={{ mr: 1 }}>
                            Sign Up
                          </Button>
                        </motion.div>
                      )}
                      {!isSignedIn && (
                        <motion.div
                          exit={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          // layout
                        >
                          <Button
                            variant="secondary"
                            onPress={onLogin}
                            sx={{ ml: 1 }}
                          >
                            Log In
                          </Button>
                        </motion.div>
                      )}
                      {!!onContactSupport && (
                        <motion.div
                          key="motion-contact-support"
                          // layout
                          exit={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                        >
                          <Button
                            onPress={onContactSupport}
                            sx={{ ml: 1 }}
                            variant="secondary"
                          >
                            Contact Support
                          </Button>
                        </motion.div>
                      )}
                    </View>
                  </RnView>
                </>
              </View>
            </View>
            <Spacer height={[4, 3, null, 0]} />
            <Sizer flex={1} flexBasis={'0%'}>
              <HomepageDevices
                // sx={{ transform: [{ scale: 0.95 }], display: ['none', null, 'flex'] }}
                sx={{
                  flex: 1,
                  transform: [null, null, `scale(.9)`],
                }}
              >
                <View sx={{ flex: 1, bg: 'background' }} />
              </HomepageDevices>
            </Sizer>
          </View>
        </View>
      </>
    </>
  )
})
