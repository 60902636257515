import React from 'react'
import { View, useDripsyTheme } from 'dripsy'
import A from '../a'
import { Platform, StyleSheet } from 'react-native'
import Entypo from '@expo/vector-icons/build/Entypo'
import useAnimatedHover from '@beatgig/helpers/use-animated-hover'

type Props = {
  url: string
  socialNetwork: string
  hovered?: boolean
} & React.ComponentProps<typeof View>

export default function SocialLink(props: Props) {
  const { sx = {}, url, socialNetwork, hovered } = props

  const { colors } = useDripsyTheme().theme
  const { ref, resetStyle, isHovered } = useAnimatedHover({
    webStyleKeys: ['opacity', 'transform'],
  })

  const shouldAnimate = isHovered || hovered

  return (
    <A
      target={Platform.OS === 'web' ? '_blank' : undefined}
      key={url}
      href={url}
      isText={false}
      ref={ref}
    >
      <View sx={sx} key={url}>
        <View
          sx={{
            ...resetStyle,
            transform: shouldAnimate ? [{ translateY: -3 }] : undefined,
          }}
        >
          <Entypo
            name={`${socialNetwork}` as any}
            color={shouldAnimate ? colors?.primary : colors?.text}
            size={30}
            style={resetStyle as any}
          />
        </View>
      </View>
    </A>
  )
}
