import React from 'react'
import { StyleSheet, View as NativeView, Platform } from 'react-native'
import { View, Image, Text } from 'dripsy'
import Spacer from '@beatgig/design/components/spacer'
import LinkToArtist from '@beatgig/navigation/links/link-to-artist'
import HoverPulse from '@beatgig/components/hover-pulse'
import Press from '@beatgig/components/press'
import Ionicons from '@expo/vector-icons/build/Ionicons'
import HoverTooltip from '@beatgig/components/hover-tooltip'
import { ArtistCardBandConfigString } from './types'
import useConsoleLog from '@beatgig/hooks/use-log'
import Badge from '@beatgig/design/components/badge'
import Sizer from '@beatgig/components/sizer'
import DefaultBandConfiguration from '@beatgig/search/types/default-band-configuration'
import NextImage, { ImageProps } from 'next/image'
import cloudinaryLoader from '@beatgig/helpers/cloudinary/next-loader'
import defaultBreakpoints from '@beatgig/theme/breakpoints'

type NextImageProps = Pick<ImageProps, 'priority' | 'loading' | 'sizes'> & {
  layout?: 'fill'
}

type Props = {
  disableNavigation?: boolean
  imageUrl?: string
  name: string
  slug: string
  width: number | string
  height: number | string
  minWidth?: number | string
  minHeight?: number | string
  id: string
  ml?: number
  mr?: number
  my?: number
  borderRadius?: number
  price?: string
  onPressPrice?: () => void
  rating?: string
  reviewCount?: number
  reviewText?: string
  reviewImageUrl?: string
  reviewAuthor?: string
  reviewAuthorOrganization?: string
  reviewLocation?: string
  reviewRating?: number
  shouldRenderReview?: boolean
  spaceLeft?: number
  hasLiked?: boolean
  // onPressLike?: () => void
  showLikeButton: boolean
  onLike: ({ artistId }: { artistId: string; slug: string }) => void
  onUnlike: ({ artistId }: { artistId: string; slug: string }) => void
  showArtistPrice: boolean
  bandConfigurations?: string
  cloudinaryId?: string
  /**
   * Used to calculate the sizes for web
   */
  itemsPerRow?: number
  smallPrice?: string
} & React.ComponentProps<typeof View> &
  NextImageProps

const USE_NEXT_IMAGE = true

const Img = React.memo(function Img({
  imageUrl,
  cloudinaryId,
  height,
  width,
  my = 0,
  ml = 0,
  mr = 0,
  priority,
  layout,
  sizes: sizesProp,
}: {
  imageUrl?: string
  cloudinaryId?: string
  height?: number | string
  width?: number | string
  mr?: number | string
  ml?: number | string
  my?: number | string
  itemsPerRow?: number
} & NextImageProps) {
  // useConsoleLog('[artist-search-image]', {
  //   layout,
  //   sizesProp,
  // })
  if (
    USE_NEXT_IMAGE &&
    Platform.OS === 'web' &&
    cloudinaryId &&
    ((typeof height === 'number' &&
      typeof width === 'number' &&
      typeof my === 'number' &&
      typeof ml === 'number' &&
      typeof mr === 'number') ||
      layout === 'fill')
  ) {
    // const sizes = () => {
    //   return (
    //     [300, 400, ...defaultBreakpoints].map((breakpoint, index) => {
    //       const isLast = index + 1 === defaultBreakpoints.length
    //       if (isLast) return true

    //       // const isFirst = index === 0

    //       const sizePadding = 100 // we want the image to be 100px bigger than its container
    //       const imageWidth = width + sizePadding

    //       return breakpoint > imageWidth
    //     }) ?? 300
    //   )
    // }
    const breakpoints = [300, 400, ...defaultBreakpoints]
    let h: number | undefined
    let w: number | undefined
    let lay: typeof layout

    // let sizes: string | undefined

    if (
      typeof height === 'number' &&
      typeof width === 'number' &&
      typeof my === 'number' &&
      typeof ml === 'number' &&
      typeof mr === 'number'
    ) {
      h = height - my * 2
      w = width - mr - ml
      // sizes = breakpoints
      //   ?.map((breakpointWidth, index) => {
      //     const breakpointPx = `${breakpoints[index]}px`
      //     const imageWidth = width < breakpointWidth ? width : breakpointWidth
      //     return `(max-width: ${breakpointPx}) ${imageWidth}px`
      //   })
      //   .join(', ')

      // sizes = `${sizes}, ${width}px`
    } else {
      lay = layout
    }

    return (
      <NextImage
        sizes={sizesProp}
        // height={'100%'}
        height={h as number}
        width={w as number}
        src={cloudinaryId}
        objectFit="cover"
        loader={cloudinaryLoader}
        priority={priority}
        layout={lay as any}
        // sizes={sizes}
        // layout="responsive"
        // layout="fill"
      />
    )
  }

  return (
    <Image
      source={{ uri: imageUrl }}
      sx={{
        height: '100%',
        width: '100%',
        flex: 1,
      }}
    />
  )
})

const ArtistSearchCard = function ArtistSearchCard(props: Props) {
  const {
    imageUrl,
    name,
    showArtistPrice,
    price = '$ 🔒',
    slug,
    width,
    height,
    id,
    ml = 0,
    mr = 0,
    my = 0,
    borderRadius = 3,
    rating,
    minHeight,
    minWidth,
    reviewCount,
    // reviewAuthor,
    // reviewLocation,
    // reviewImageUrl,
    // reviewRating,
    // reviewText,
    // shouldRenderReview,
    spaceLeft = 0,
    hasLiked = false,
    showLikeButton = false,
    onLike,
    onUnlike,
    onPressPrice,
    bandConfigurations,
    cloudinaryId,
    priority,
    loading,
    itemsPerRow,
    layout,
    sizes,
    smallPrice,
    disableNavigation,
  } = props

  const showRating = !!(rating && reviewCount)

  const Container = disableNavigation ? View : LinkToArtist

  return (
    <Container
      web={{
        prefetch: false,
      }}
      slug={slug}
    >
      <View sx={{ flexDirection: 'row' }}>
        <View sx={{ pr: spaceLeft }} />
        <View>
          <View
            sx={{
              width,
              minWidth,
              height,
              minHeight,
            }}
          >
            <View
              sx={{
                my: my + 0.0000001,
                ml: ml + 0.0000001,
                mr: mr + 0.0000001,
                flex: 1,
                overflow: 'hidden',
                borderRadius,
              }}
            >
              <HoverPulse sx={{ flex: 1 }}>
                <Img
                  cloudinaryId={cloudinaryId}
                  width={width}
                  height={height}
                  imageUrl={imageUrl}
                  ml={ml}
                  my={my}
                  mr={mr}
                  priority={priority}
                  loading={loading}
                  layout={layout}
                  sizes={sizes}
                />
              </HoverPulse>

              <NativeView pointerEvents="box-none" style={styles.topContent}>
                <NativeView pointerEvents="box-none" style={styles.topStats}>
                  {showRating && (
                    <View
                      sx={{
                        // position: 'absolute',
                        // top: 0,
                        // right: 0,
                        bg: 'primary',
                        //   bg: 'red',
                        pl: 1,
                        pr: 1,
                        mt: 1,
                        borderTopRightRadius: 3,
                        borderBottomRightRadius: 3,
                        flexDirection: 'row',
                        alignItems: 'center',
                        zIndex: 0,
                      }}
                    >
                      <Ionicons name="ios-star" size={14} />
                      <Text
                        sx={{
                          color: 'background',
                          fontWeight: 'bold',
                          fontSize: 2,
                          pl: (theme) => theme.space[1] / 2 + 0.01,
                        }}
                      >
                        {rating}{' '}
                        <Text sx={{ fontWeight: 'normal', color: 'muted3' }}>
                          ({reviewCount})
                        </Text>
                      </Text>
                    </View>
                  )}
                  {!!price && (
                    <Sizer
                      display={
                        // if price is hidden
                        // or if we don't have band config pricing
                        !showArtistPrice || !bandConfigurations
                          ? 'flex'
                          : ['flex', null, 'none']
                      }
                    >
                      <View
                        sx={{
                          bg: (theme) => `${theme.colors?.background}90`,
                          pr: 1,
                          pl: (theme) => theme.space[1] / 2 - 0.000001,
                          mt: 2,
                          borderTopRightRadius: 3,
                          borderBottomRightRadius: 3,
                          justifyContent: 'center',
                          zIndex: 1,
                        }}
                      >
                        <HoverTooltip
                          onPress={(e) => {
                            if (onPressPrice) {
                              e.preventDefault()
                              onPressPrice()
                            }
                          }}
                          anchor="left"
                          placement="bottom"
                          borderColor="primary"
                          text={
                            !showArtistPrice && (
                              <Text>
                                <Text
                                  sx={{ color: 'primary', fontWeight: 'bold' }}
                                >
                                  Sign up
                                </Text>{' '}
                                to see artist prices.
                              </Text>
                            )
                          }
                          textSx={{ maxWidth: width }}
                        >
                          <Sizer display={['flex', 'flex', 'none']}>
                            <Text sx={{ fontWeight: 'bold', lineHeight: 3 }}>
                              {smallPrice ?? price}
                            </Text>
                          </Sizer>
                          <Sizer display={['none', 'none', 'flex']}>
                            <Text sx={{ fontWeight: 'bold', lineHeight: 3 }}>
                              {price}
                            </Text>
                          </Sizer>
                        </HoverTooltip>
                      </View>
                    </Sizer>
                  )}
                </NativeView>
                {showLikeButton && (
                  <Press
                    sx={
                      {
                        p: 2,
                        zIndex: 0,
                      } as any
                    }
                    onPress={(e) => {
                      e.preventDefault()
                      if (hasLiked) {
                        onUnlike({ artistId: id, slug })
                      } else {
                        onLike({ artistId: id, slug })
                      }
                    }}
                  >
                    <Ionicons
                      size={23}
                      name={hasLiked ? 'heart' : 'heart-outline'}
                      color={hasLiked ? 'red' : 'white'}
                      style={{
                        textShadowOffset: { width: 1, height: 1 },
                        textShadowRadius: 5,
                        textShadowColor: hasLiked ? '#00000050' : '#00000099',
                      }}
                    />
                  </Press>
                )}
              </NativeView>
              <NativeView pointerEvents="none" style={styles.content}>
                {showArtistPrice && (
                  <BandConfigurationChips
                    bandConfigurations={bandConfigurations}
                  />
                )}
                {/* <Spacer height={[1, 1, 2]} /> */}
                <Text
                  sx={
                    {
                      fontWeight: 'bold',
                      fontSize: name?.length > 20 ? 4 : 5,
                      px: 2,
                      textShadowOffset: { width: 1, height: 1 },
                      textShadowRadius: 5,
                      textShadowColor: '#00000099',
                    } as any
                  }
                >
                  {name}
                </Text>
                <Spacer height={2} />
              </NativeView>
            </View>
            {/* <BandConfigurationChips bandConfigurations={bandConfigurations} /> */}
          </View>
        </View>
      </View>
    </Container>
  )
}

const BandConfigurationChips = React.memo(function BandConfigurationChips(
  props: Pick<Props, 'bandConfigurations'>
) {
  const bandConfigurations: ArtistCardBandConfigString = JSON.parse(
    props.bandConfigurations || '{}'
  )
  const configurationsArray = Object.entries(bandConfigurations)
  const items = configurationsArray.map(
    ([key, { formattedRangeString, isHighlightedConfiguration, title }], i) => {
      let shouldShowTitle = false
      if (configurationsArray.length > 1) {
        shouldShowTitle = true
      } else if (key !== DefaultBandConfiguration) {
        shouldShowTitle = true
      }
      return (
        <Badge
          sx={
            {
              mx: 1,
              my: 1,
              shadowOffset: { width: 1, height: 1 },
              shadowRadius: 5,
              shadowColor: isHighlightedConfiguration && '#00000095',
              bg: isHighlightedConfiguration ? 'text' : '#00000095',
            } as any
          }
          textSx={{
            // fontSize: 1,
            // fontWeight: 'normal',
            textTransform: 'capitalize',
            color: isHighlightedConfiguration ? 'background' : 'text',
          }}
          key={key}
          variant={'default'}
        >
          {shouldShowTitle && (
            <>
              {title}
              {': '}
            </>
          )}
          {formattedRangeString}
        </Badge>
      )
    }
  )
  // return (
  //   <ScrollView
  //     sx={{ px: 1 }}
  //     horizontal
  //     showsHorizontalScrollIndicator={false}
  //   >
  //     {items}
  //   </ScrollView>
  // )
  return (
    <View pointerEvents="none">
      <Sizer
        {...{
          flexDirection: 'row',
          flexWrap: 'wrap',
          mx: 1,
          display: ['none', 'none', 'flex'],
        }}
      >
        {items}
      </Sizer>
    </View>
  )
})

ArtistSearchCard.BandConfigurationChips = BandConfigurationChips

const style = {
  text: { fontWeight: 'bold', fontSize: 6 },
}

export default React.memo(ArtistSearchCard)

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  image: {
    // flex: 1,
    borderRadius: 8,
  },
  content: {
    ...StyleSheet.absoluteFillObject,
    top: 'auto',
  },
  text: { fontWeight: 'bold', fontSize: 6 },
  topContent: {
    ...StyleSheet.absoluteFillObject,
    bottom: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: 1,
    // flexDirection: 'column-reverse',
  },
  topStats: {
    alignItems: 'flex-start',
    zIndex: 1,
  },
  configs: {},
})
