import 'setimmediate'
// @ts-ignore
if (!global.setImmediate) global.setImmediate = setTimeout
import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { styled, useDripsyTheme, useResponsiveValue } from 'dripsy'
import { NegotiationAction } from '@beatgig/api-services'
import { Bar } from 'expo-progress'
import useLayout from '@beatgig/hooks/use-layout'
import last from 'lodash.last'
import { DateTime } from 'luxon'
import isAfter from 'date-fns/isAfter'
import { BookingDisplay } from '@beatgig/api-services/booking'

type Props = {
  variant?: 'mini' | 'default'
  statusPosition?: ('hidden' | 'under' | 'right' | 'left')[]
  booking?: BookingDisplay
  color?: string
  barProps?: React.ComponentProps<typeof Bar>
  labelSx?: React.ComponentProps<typeof Container>['sx']
  shouldFillOnLastStep?: boolean
} & React.ComponentProps<typeof Container>

const Container = styled(View)({})

const Wrapper = styled(View)({
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
})

const BarContainer = styled(View)({ flex: 1 })

const StatusUnderContainer = styled(View)({ flexDirection: 'row', mt: 2 })
const StatusRightContainer = styled(View)({ ml: 2 })
const StatusLeftContainer = styled(View)({ mr: 2 })

const StatusItemLabel = styled(Text, {
  defaultVariant: 'body',
  themeKey: 'text',
})({
  color: 'text',
  textAlign: 'center',
  textTransform: 'capitalize',
})

const dotSize = 15

const Dot = styled(View)({
  height: dotSize,
  width: dotSize,
  borderRadius: dotSize / 2,
  marginTop: -dotSize / 2 + dotSize / 4,
})

const DotContainer = styled(View)({
  ...StyleSheet.absoluteFillObject,
  flexDirection: 'row',
  justifyContent: 'space-around',
})

const StatusItem = styled(View)({})

const BookingProgressBar = React.memo(function BookingProgressBar(
  props: Props
) {
  const {
    sx = {},
    variant = 'default',
    statusPosition: statusPositionArray = ['under'],
    booking,
    color = 'primary',
    barProps = {},
    labelSx = {},
    shouldFillOnLastStep = false,
  } = props
  const statusPosition = useResponsiveValue(statusPositionArray)
  const { colors } = useDripsyTheme().theme

  const areDotsVisible = variant !== 'mini'

  const { onLayout, width, height } = useLayout()

  if (!booking?.status_information) return null

  const { status_information } = booking
  const trackColor = colors?.muted2

  const {
    // current_step: activeStep,
    current_step_index,
    total_step_count,
    status_bar,
  } = status_information

  if (typeof current_step_index === 'undefined') return null

  // const stepColors = ['secondary', 'accent', 'success', 'primary']
  // const stepColor = stepColors[activeStep.index] ?? 'primary'

  const stepColor = color

  const isWideEnoughForAllItems = width > 400
  const renderStatusLabels = () => {
    if (statusPosition === 'hidden') return false
    // const statusItemWidth = width / total_step_count
    // const statusItemOffsetLeft = statusItemWidth / 2

    return (
      <>
        {new Array(total_step_count).fill('').map((_, index) => {
          // const color = stepColors[index] ?? 'text'
          const step = status_bar[index]
          const label = step.status

          const isActiveStep = step.status === booking.status
          if (variant === 'mini' && !isActiveStep) return null
          return (
            <StatusItem
              sx={{
                // width: `${(1 / total_step_count) * 100}%`,
                // display:
                //   isWideEnoughForAllItems || isActiveStep ? 'flex' : 'none',
                flex: 1,
              }}
              key={label}
            >
              <StatusItemLabel
                sx={{
                  color: isActiveStep ? color : 'text',
                  fontWeight: isActiveStep ? 'bold' : undefined,
                  fontSize: [1, 2],
                  ...labelSx,
                }}
              >
                {label}
              </StatusItemLabel>
            </StatusItem>
          )
        })}
      </>
    )
  }

  const renderDots = () => {
    if (!areDotsVisible) return null
    return new Array(total_step_count).fill('').map((_, index) => {
      // const isIncomplete = index > activeStep.index
      const isIncomplete = index > current_step_index
      const dotSize = height * 1.75
      return (
        <Dot
          sx={{
            bg: isIncomplete ? trackColor : stepColor,
            height: dotSize,
            width: dotSize,
            // borderRadius: dotSize / 2,
            borderRadius: 'rounded',
            marginTop: -dotSize / 4 + 0.000001,
          }}
          key={`dot-${index}`}
        />
      )
    })
  }

  let progress = (current_step_index + 1) / total_step_count

  const hasBookingStarted =
    current_step_index + 1 === total_step_count &&
    typeof booking.start_time === 'string' &&
    booking.venue_location.timezone &&
    isAfter(
      new Date(),
      DateTime.fromISO(booking.start_time)
        .setZone(booking.venue_location.timezone)
        .toJSDate()
    )

  if (
    (current_step_index + 1 < total_step_count || !shouldFillOnLastStep) &&
    areDotsVisible &&
    !hasBookingStarted
  ) {
    // since we aren't complete, we make the progress look lesser so that it lines up with the dots
    progress = progress - 1 / total_step_count / 2
  }

  const lastAction = last(booking?.negotiation_steps)?.action

  if (lastAction === NegotiationAction.CANCEL) {
    return null
  }

  return (
    <Container sx={sx}>
      <Wrapper>
        {statusPosition === 'left' && (
          <StatusLeftContainer>{renderStatusLabels()}</StatusLeftContainer>
        )}
        <BarContainer onLayout={onLayout}>
          <Bar
            {...barProps}
            trackColor={trackColor as string}
            color={colors?.[stepColor] as string}
            progress={progress}
            isAnimated
            // style={{ borderRadius: 0 }}
          />
          <DotContainer>{renderDots()}</DotContainer>
        </BarContainer>
        {statusPosition === 'right' && (
          <StatusRightContainer>{renderStatusLabels()}</StatusRightContainer>
        )}
      </Wrapper>
      {statusPosition === 'under' && (
        <StatusUnderContainer>{renderStatusLabels()}</StatusUnderContainer>
      )}
    </Container>
  )
})

export default BookingProgressBar
