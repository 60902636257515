/** @jsx jsx */
import { jsx } from '@theme-ui/core'
import { useThemeUI } from '@theme-ui/core'
import viewResetStyle from '@beatgig/helpers/view-reset-style'
import { GradientTextProps } from './types'
import React from 'react'

const Div = React.createElement('div')

export default function GradientText({
  children,
  sx,
  colors = ['accent', 'secondary'],
  animated,
  angle = 0,
  as,
  accessibilityRole,
}: GradientTextProps) {
  const { theme } = useThemeUI()
  const finalColors = colors.map((color) => theme.colors?.[color] ?? color)
  const finalSx = {
    // ...viewResetStyle,
    // variant: 'text.body',
    ...sx,
  }
  return (
    <div
      sx={{
        ...viewResetStyle,
        zIndex: 1,
        '::before': animated
          ? {
              ...finalSx,
              content: `"${children}"`,
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              color: 'text',
              zIndex: 0,
              right: 0,
              // display: ['none', 'none', 'flex'],
              display: ['none', 'none', 'block'],
            }
          : undefined,
      }}
      // @ts-ignore
      accessibilityRole={accessibilityRole}
    >
      <div
        sx={
          {
            ...finalSx,
            display: 'block',
            // background: finalColors[0],
            background: `-webkit-linear-gradient(${angle}deg, ${finalColors.join(
              ', '
            )})`,
            backgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            WebkitBackgroundClip: 'text',
            textFillColor: 'transparent',
            position: 'relative',
            animation: [null, null, `fadeIn 2s 1s ease-in-out forwards`],
            opacity: [null, null, 0],
            // zIndex: 2,
          } as any
        }
        aria-hidden
      >
        {children}
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      `,
        }}
      />
    </div>
  )
}
