import { User } from '@beatgig/api/user'
// import useAmISignedIn from './use-am-i-signed-in'
import useMyAccount from './use-my-account'

export default function useCanISeeArtistPrice() {
  const { data, isLoading } = useMyAccount()
  // const { amISignedIn } = useAmISignedIn()

  return {
    // anyone with an account can view
    // canSeeArtistPrice: !!data?.user_role,
    canSeeArtistPrice:
      User.isApproved(data) || User.isAdmin(data) || User.isOwner(data),
    isLoading,
  }
}
