import { useCallback } from 'react'

// TODO hook this into dripsy breakpoints
const breakpoints = new Array(5).fill('').map((_, i) => i)

export default function useStyleArray() {
  /**
   * `map` accepts a responsive style array, and lets you map over it to create a different style based on it.
   *
   * @example
   * Say you have a `View` whose height is `[10,null,30]`, and you want to style something else based on it. If you simply map it, the `null` value won't work, even though it works with dripsy.
   *
   * ```jsx
   * const height = [10, null, 30]
   * // 🚨 this breaks
   * const width = height.map(h => h / 2)
   *
   * // ✅ this works
   * const width = map(height, h => h / 2)
   *
   * <Box sx={{ height, width }} />
   * ```
   *
   * @note the first item cannot be null for this to work
   */
  const map = useCallback(
    <T = any, R = any>(values: T[], iterator: (item: T | null) => R) => {
      if (values[0] == null)
        console.error(
          `useStyleArray.map error: the first item was null. This is invalid.`,
          values
        )
      return breakpoints.map((index) => {
        // get the value at the current breakpoint, or at the
        const nearestBreakpoint = (breakpointIndex: number): number => {
          // mobile-first breakpoints
          if (breakpointIndex <= 0 || typeof breakpointIndex !== 'number')
            return 0

          if (values[breakpointIndex] == null) {
            // if this value doesn't have a breakpoint, find the previous, recursively
            return nearestBreakpoint(breakpointIndex - 1)
          }
          return breakpointIndex
        }
        return iterator(values[nearestBreakpoint(index)])
      })
    },
    []
  )

  return {
    map,
  }
}
