import { Platform } from 'react-native'
import { Artist } from '@beatgig/api-services'
import { makeArtistSlug } from './artist-routes'

const PublicRoutes = {
  venue: ({ slug }: { slug: string }) =>
    ({
      routeName: 'venue',
      web: { path: `/venues/[slug]`, as: `/venues/${slug}` },
      params: { slug },
    } as const),
  events: () =>
    ({
      routeName: 'events',
    } as const),
  artist: ({ slug, artist }: { slug: string; artist?: Artist }) =>
    ({
      routeName: 'Artist',
      web: { path: `/[slug]`, as: `/${makeArtistSlug(slug)}`, prefetch: false },
      params: {
        slug,
        // id: artist?.id,
        // artist: Platform.select({
        //   web: artist ? JSON.stringify(artist) : null,
        //   default: artist ?? ({} as any),
        // }),
      },
      native: {
        navigationBehavior: 'push',
        isNativeStack: true,
      },
    } as const),
  home: () =>
    ({
      routeName: '/',
      web: {
        path: '/',
      },
    } as const),
  marketingPage: (product: 'artists' | 'colleges' | 'venues') => {
    return {
      web: { path: `/products/${product}` },
      routeName: `/products/${product}`,
    } as const
  },
}

export default PublicRoutes
