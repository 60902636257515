import { useRouting } from 'expo-next-react-navigation'
import { User } from '@beatgig/api/user'
import useMyAccount from './use-my-account'
import { useRef, useEffect } from 'react'
import useAuthPopupState from './use-auth-popup-state'
import useOpenAuthPopup from './use-open-auth-popup'
import useAuth from './use-auth'
import usePublicUser from '@beatgig/api-hooks/src/use-public-user'

/**
 * Call this on the home page, for instance. Use it sparingly, if at all.
 */
export default function useAutomaticallyOpenOnboardingPopup() {
  const open = useOpenAuthPopup()
  const hasOpened = useRef(false)
  const { data } = useMyAccount()

  const { getParam } = useRouting()

  const referredBySlug: string | undefined = getParam('referredBy')

  const { data: referredByUser } = usePublicUser(
    referredBySlug ? { slug: referredBySlug } : null
  )

  const [isOpen] = useAuthPopupState()

  useEffect(
    function markOpenedBasedOnState() {
      if (isOpen && !hasOpened.current) {
        hasOpened.current = true
      }
    },
    [isOpen]
  )

  useEffect(
    function automaticallyOpenWithReferredBySlug() {
      if (
        referredBySlug &&
        !hasOpened.current
        // !loading &&
        // !user &&
        // referredByUser
      ) {
        hasOpened.current
        open()
      }
    },
    [open, referredBySlug]
  )

  useEffect(
    function automaticallyOpenIfNeedsOnboarding() {
      if (!hasOpened.current && User.needsToOnboard(data)) {
        hasOpened.current = true
        open()
      }
    },
    [data, open]
  )
}
