import React from 'react'
import { Link } from 'expo-next-react-navigation'
import { Artist } from '@beatgig/api-services'
import { useRouting } from 'expo-next-react-navigation'
import { styled } from 'dripsy'
import PublicRoutes from '../routes/public-routes'
import Press from '@beatgig/components/press'
import useConsoleLog from '@beatgig/hooks/use-log'
type Props = {
  artist?: Artist
  slug: string
  id?: string
  isText?: boolean
  shouldAddArtistToCacheOnPress?: boolean
} & Omit<React.ComponentProps<typeof Link>, 'params' | 'routeName'>

const StyledLink = styled(Link)({})

export default React.forwardRef(function LinkToArtist(
  {
    artist,
    children,
    slug,
    id,
    isText = false,
    shouldAddArtistToCacheOnPress = false,
    native = {},
    touchableOpacityProps = {},
    web = {},
    ...props
  }: Props,
  ref
) {
  const { navigate } = useRouting()
  // let web = { path: '/artists/[id]', as: `/artist/${id ?? artist?.id}` }
  // if (slug ?? artist?.slug) {
  //   web = { path: '/[slug]', as: `/${makeArtistSlug(slug ?? artist?.slug)}` }
  // }

  const link = PublicRoutes.artist({ artist, slug })
  const {
    navigationBehavior = link.native.navigationBehavior,
    isNativeStack = link.native.isNativeStack,
    ...otherNative
  } = native
  const onPress = (e) => {
    e.preventDefault()
    console.log('[navigate to artist] pressed', { link })
    navigate({
      ...link,
      native: {
        // navigationBehavior,
        // isNativeStack,
        ...link.native,
        navigationBehavior,
        isNativeStack,
        ...otherNative,
      },
      web: {
        ...link.web,
        ...web,
      },
    })
  }

  // return (
  //   <Press ref={ref as any} onPress={onPress}>
  //     {children}
  //   </Press>
  // )

  return (
    <StyledLink
      // routeName="Artist"
      // params={{
      //   // we might not include an artist if we don't want it cached.
      //   artist: artist ? JSON.stringify(artist) : null,
      //   id: id ?? artist?.id,
      //   slug: slug ?? artist?.slug,
      // }}
      routeName={link.routeName}
      params={link.params}
      // web={{ path: '/artist/[id]', as: `/artist/${artist?.id}` }}
      // web={{ path: '/[slug]', as: `/${makeArtistSlug(artist?.slug)}` }}
      web={{ ...link.web, ...web }}
      isText={isText}
      native={{
        // navigationBehavior,
        // isNativeStack,
        ...link.native,
        navigationBehavior,
        isNativeStack,
        ...otherNative,
      }}
      touchableOpacityProps={{
        activeOpacity: 1,
        onPressOut: (e) => {
          // if (
          //   shouldAddArtistToCacheOnPress &&
          //   !cache.get(ArtistApi.getSWRKey({ slug })) &&
          //   artist
          // ) {
          //   ArtistApi.mutateSWRCacheFromServer(artist)
          // }
          touchableOpacityProps?.onPressOut?.(e)
        },
        ...touchableOpacityProps,
      }}
      ref={ref as any}
      {...props}
    >
      {children}
    </StyledLink>
  )
})
