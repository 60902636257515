import { useRef, useState, useEffect, useCallback, useMemo } from 'react'
// import { useBreakpointIndex } from 'dripsy'
import { FlatList as FlatListType, Dimensions } from 'react-native'
import last from 'lodash.last'
import defaultBreakpoints from '@beatgig/theme/breakpoints'
import { useDripsyTheme } from 'dripsy'

type ViewToken<Data> = {
  item: Data
  index: number | null
}

type Props<Data> = {
  data: Data[]
  /**
   * To disable, set to `-1`. Default: `2`. Anything below this breakpoint won't set state.
   */
  minimumBreakpoint?: number
  //   shouldScrollBackToStartAtEnd?: boolean
  shouldLoopInfinitely?: boolean
}

export default function useFlatlistRowArrows<Data extends { id: string }>({
  data: inputData,
  minimumBreakpoint = 2,
  shouldLoopInfinitely = false,
}: //   shouldScrollBackToStartAtEnd = true
Props<Data>) {
  const [finalData, setFinalData] = useState(inputData)
  useEffect(() => {
    if (shouldLoopInfinitely) setFinalData(inputData)
  }, [inputData, shouldLoopInfinitely])
  const data = shouldLoopInfinitely ? finalData : inputData
  const flatlist = useRef<FlatListType<Data>>(null)
  const [viewableItems, setViewableItems] = useState<ViewToken<Data>[]>([])

  const { space } = useDripsyTheme().theme

  // const breakpointIndex = useBreakpointIndex()

  const viewabledata = useRef<ViewToken<Data>[]>()
  const lastViewableScrollIndex = useRef<null | number>(null)

  const canScrollRight = useMemo(
    () =>
      !!(
        viewableItems?.length &&
        // the last item is not in view
        !viewableItems?.some(({ item }) => item?.id === last(data)?.id)
      ),
    [data, viewableItems]
  )

  const loopCount = useRef(0)
  const onScrollBackToStart = useCallback(() => {
    if (shouldLoopInfinitely) {
      loopCount.current = loopCount.current + 1
      setFinalData((state) => [
        ...state,
        ...state
          // only duplicate the original id
          .filter(({ id }) => !id.includes('-loop'))
          .map((artist) => ({
            ...artist,
            // we need stable IDs, so we set pseudo ones here.
            id: `${artist.id}-loop${loopCount.current}`,
          })),
      ])
      setTimeout(() => {
        const anchorIndex = viewableItems?.[0]?.index || 0
        let nextIndex = anchorIndex + 2
        if (!data?.[nextIndex]) {
          nextIndex = anchorIndex + 1
        }

        const artistToScrollToExists = data?.[nextIndex]
        if (artistToScrollToExists) {
          flatlist.current?.scrollToIndex({ index: nextIndex, animated: true })
        }
      }, 150)
      return
    }
    flatlist.current?.scrollToOffset({
      animated: true,
      offset: space?.[5] * -1,
    })
    // flatlist.current?.scrollToIndex({ index: 0, animated: true })
  }, [data, shouldLoopInfinitely, space, viewableItems])

  const onScrollRight = useCallback(() => {
    if (!viewableItems?.length || !canScrollRight) return

    const lastVisibleIndex = last(viewableItems)?.index

    // it's left-anchored, so we just need to scroll to the last visible item.
    // let nextIndex = lastVisibleIndex
    const anchorIndex = viewableItems?.[0]?.index || 0
    let nextIndex = anchorIndex + 2
    if (!data?.[nextIndex]) {
      nextIndex = anchorIndex + 1
    }

    const artistToScrollToExists = data?.[nextIndex]
    if (artistToScrollToExists) {
      flatlist.current?.scrollToIndex({ index: nextIndex, animated: true })
    }
  }, [data, canScrollRight, viewableItems])
  const canScrollLeft = useMemo(
    () =>
      !!(
        viewableItems?.length &&
        // first item is not in view
        !viewableItems?.some(({ item }) => item?.id === data?.[0]?.id)
      ),
    [data, viewableItems]
  )

  const onScrollLeft = useCallback(() => {
    console.log('pressed scroll left', { viewableItems, canScrollLeft })
    if (!canScrollLeft) return

    const firstVisibleIndex = viewableItems?.[0]?.index
    if (firstVisibleIndex == null) return

    const firstListItemIsVisible = viewableItems?.some(
      ({ item }) => item.id === data?.[0]?.id
    )
    if (firstListItemIsVisible && flatlist.current) {
      onScrollBackToStart()
      return
    }

    let prevIndex = firstVisibleIndex - 2
    if (!data?.[prevIndex]) {
      prevIndex = firstVisibleIndex - 1
    }

    const artistToScrollToExists = data?.[prevIndex]
    if (artistToScrollToExists) {
      flatlist.current?.scrollToIndex({ index: prevIndex, animated: true })
    }
  }, [viewableItems, canScrollLeft, data, onScrollBackToStart])

  const onChanged = useCallback(
    ({ viewableItems }) => {
      const getBreakpoint = () => {
        const breakpointPixels = [...defaultBreakpoints]
          .reverse()
          .find((breakpoint) => Dimensions.get('window').width >= breakpoint)

        let breakpointIndex = defaultBreakpoints.findIndex(
          (breakpoint) => breakpointPixels === breakpoint
        )
        breakpointIndex = breakpointIndex === -1 ? 0 : breakpointIndex + 1
        return breakpointIndex
      }
      const breakpointIndex = getBreakpoint()
      if (breakpointIndex < minimumBreakpoint) return
      const itemsThatAreViewable = viewableItems.filter(
        ({ isViewable }) => isViewable
      )
      // if (itemsThatAreViewable !== viewabledata.current) {
      setViewableItems(itemsThatAreViewable)
      // }
      viewabledata.current = itemsThatAreViewable
      const indexVisible = viewabledata.current
        ?.map(({ index }) => index)
        .filter((index) => index !== null) as number[] | undefined

      if (indexVisible) {
        lastViewableScrollIndex.current = Math.max(...indexVisible)
      }
    },
    [minimumBreakpoint]
  )

  const onViewableItemsChangedRef = useRef(onChanged)
  useEffect(() => {
    onViewableItemsChangedRef.current = onChanged
  })
  const onViewableItemsChanged = useRef((items) =>
    onViewableItemsChangedRef.current(items)
  ).current

  const viewabilityConfig = useRef({
    itemVisiblePercentThreshold: 100,
  }).current

  // console.log('[use-flatlist-rows]', { data, finalData })

  return {
    viewabilityConfig,
    onViewableItemsChanged,
    onScrollRight,
    onScrollLeft,
    canScrollLeft,
    canScrollRight,
    onScrollBackToStart,
    flatlist,
    finalData: shouldLoopInfinitely ? finalData : data,
  }
}
