import React from 'react'
import { View, useDripsyTheme } from 'dripsy'
import useTalentCardDimensions from '../talent-card/use-talent-card-dimensions'
import { TouchableOpacity, Platform } from 'react-native'
import Press from '@beatgig/components/press'
import Ionicons from '@expo/vector-icons/build/Ionicons'

type Props = {
  // isHovered: boolean
  canScrollLeft: boolean
  canScrollRight: boolean
  onScrollLeft: () => void
  onScrollRight: () => void
  onScrollBackToStart: () => void | null
  shouldScrollBackToStartAtEnd: boolean
  // resetStyle: Record<string, any>
} & React.ComponentProps<typeof View>

export default React.memo(function DiscoverSectionArrowOverlay(props: Props) {
  const {
    sx = {},
    // isHovered = false,
    canScrollLeft,
    canScrollRight = true,
    onScrollLeft,
    onScrollRight,
    shouldScrollBackToStartAtEnd = true,
    onScrollBackToStart,
    // resetStyle,
  } = props

  const resetStyle = {}

  const { height } = useTalentCardDimensions()
  const { colors, space } = useDripsyTheme().theme

  if (Platform.OS !== 'web' || !(canScrollLeft || canScrollRight)) return null

  const renderArrow = ({ direction }: { direction: 'right' | 'left' }) => {
    const isRight = direction === 'right'
    let show = true
    if (isRight) {
      if (!shouldScrollBackToStartAtEnd) {
        show = show && canScrollRight
      }
    } else {
      show = show && canScrollLeft
    }
    let onPress = onScrollLeft
    if (isRight) {
      if (canScrollRight) {
        onPress = onScrollRight
      } else if (shouldScrollBackToStartAtEnd && onScrollBackToStart) {
        onPress = onScrollBackToStart
      }
    }

    // const icon = () as const

    return (
      <Press
        onPress={show ? onPress : undefined}
        pointerEvents={show ? undefined : 'none'}
        sx={{
          // height: '100%',
          py: 2,
          // px: 3,
          width: space?.[5],
          alignItems: 'center',
          bg: (theme) => `${theme.colors?.background}99`,
          opacity: show ? 1 : 0,
          transitionProperty: 'opacity',
          transitionDuration: '0.25s',
          // height,
          height: '100%',
          position: 'absolute',
          top: 0,
          right: isRight ? 0 : 'auto',
          left: isRight ? 'auto' : 0,
          display: ['none', 'none', 'flex'],
        }}
      >
        {({ hovered }) => (
          <View
            sx={{
              ...resetStyle,
              transform: [{ scale: hovered ? 1.4 : 1 }],
              justifyContent: 'center',
              flex: 1,
              transitionProperty: 'transform',
              transitionDuration: '0.25s',
            }}
          >
            <Ionicons
              name={isRight ? 'chevron-forward' : 'chevron-back'}
              color={colors?.text}
              size={40}
            />
          </View>
        )}
      </Press>
    )
  }

  return (
    <>
      {renderArrow({ direction: 'left' })}
      {renderArrow({ direction: 'right' })}
    </>
  )
})
