import { useCallback, useRef, useEffect } from 'react'
import usePost from '../src/use-post'
import { User } from '@beatgig/api/user'
import useAuth from '@beatgig/auth-hooks/use-auth'
import { Like } from '@beatgig/api-services/user'
import { UseAsyncCallbackOptions } from 'react-async-hook'

type Props = {
  /**
   * Use this callback to open a sign-in modal when an unauthenticated user presses like.
   */
  onPressWhileUnauthenticated?: () => void
  /**
   * Default: `true`. If true, it will not send a like request while another is already loading.
   */
  preventRequestWhileLoading?: boolean
}

export default function useLikeArtistMemo(
  // artistId?: string,
  {
    onPressWhileUnauthenticated = () => alert('cannot like without an account'),
    // preventRequestWhileLoading = true,
    onSuccess,
    ...options
  }: Props & UseAsyncCallbackOptions<Like> = {}
) {
  const { user } = useAuth()
  const isLiking = useRef<Record<string, boolean>>({})
  const { loading, execute, ...rest } = usePost(
    async ({
      unlike = false,
      artistId,
    }: {
      unlike?: boolean
      artistId: string
    }) => {
      if (!user?.uid) {
        onPressWhileUnauthenticated?.()
        return null
      }

      isLiking.current[artistId] = true
      //   User.addArtistLikeToCache(artist, { unlike, uid: user.uid })
      User.addArtistLikeIDToCache({ artistId }, { unlike, uid: user.uid })
      let response: Like | null = null
      try {
        response = await User.likeArtist({ id: artistId }, { unlike })
        isLiking.current[artistId] = false
      } catch (e) {
        isLiking.current[artistId] = false
        throw e
      }

      return response
    },
    {
      onSuccess: (data, options) => {
        if (data) onSuccess?.(data, options)
      },
      ...options,
    }
  )

  const run = useRef(execute)
  useEffect(() => {
    run.current = execute
  }, [execute])

  const like = useRef(({ artistId }: { artistId: string }) => {
    if (isLiking.current[artistId]) {
      console.log('[use-like-artist] button smash prevented')
      return null
    }
    return run.current({ unlike: false, artistId })
  }).current

  const unlike = useRef(({ artistId }: { artistId: string }) => {
    if (isLiking.current[artistId]) {
      console.log('[use-like-artist] button smash prevented')
      return null
    }
    return run.current({ unlike: true, artistId })
  }).current

  return {
    ...rest,
    like,
    loading,
    unlike,
  }
}
