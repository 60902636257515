import React from 'react'
import { View, Text } from 'dripsy'
import { ArtistRow } from '@beatgig/api-services/discover'
import Link from '@beatgig/design/components/link'

type Props = {
  placeholder?: boolean
  children?: React.ReactNode
  onPressSeeMore: (searchData: ArtistRow['search_data']) => void
  searchData?: ArtistRow['search_data']
} & React.ComponentProps<typeof Text>

export default function DiscoverSectionArtistRowTitle(props: Props) {
  const {
    sx = {},
    children,
    placeholder = false,
    searchData,
    onPressSeeMore,
  } = props

  return (
    <View
      sx={{
        flexDirection: 'row',
        // justifyContent: 'space-between',
        alignItems: 'center',
        mb: 1,
      }}
    >
      <Text
        sx={{
          color: 'text',
          // fontWeight: ['800', null, '700'],
          fontWeight: '700',
          fontSize: [5, 6, 7],
          mx: 2,
          pl: [0, 5],
          ...sx,
        }}
      >
        {placeholder ? ' ' : children}
      </Text>
      {!!searchData && (
        <Link
          icon="hover"
          block
          color="primary"
          iconName="chevron-forward"
          onPress={() => onPressSeeMore(searchData)}
        >
          See More
        </Link>
      )}
    </View>
  )
}
