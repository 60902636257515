import { useCallback } from 'react'
import { useRouting } from 'expo-next-react-navigation'

export default function useNavigateToSearch() {
  const { navigate } = useRouting()
  return {
    navigate: useCallback(() => {
      navigate({
        routeName: 'search',
      })
    }, [navigate]),
  }
}
