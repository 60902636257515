import React from 'react'
import { Text, Row } from 'dripsy'

import Spacer from '@beatgig/design/components/spacer'
import Picker from '@beatgig/components/picker'
import Button from '@beatgig/components/button'
import View from '@beatgig/components/native-view'
import Card from '@beatgig/design/components/card'
import Description from '@beatgig/design/components/description'
import Input from '@beatgig/design/components/input'
import useTheme from '@beatgig/theme/use-theme'

export default function MarketingBankPayment({
  price,
  onPress,
  sx,
  status,
}: {
  price: string
  onPress?: () => void
  sx?: React.ComponentProps<typeof Card>['sx']
  status?: 'loading' | 'success' | null
}) {
  const { colors, space } = useTheme()

  return (
    <Card
      sx={{
        alignSelf: 'center',
        maxWidth: 550,
        borderColor: 'blue',
        ...sx,
      }}
      // variant="success"
      type="outline"
    >
      <Spacer height={[2, 3]} />
      <Card.Content sx={{ alignSelf: 'center', width: '100%', maxWidth: 400 }}>
        <View
        // sx={{
        //   display: ['none', null, 'flex'],
        // }}
        >
          <Row
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Text sx={{ fontWeight: 'bold', fontSize: 5, textAlign: 'center' }}>
              Invoice
            </Text>
            {/* <Spacer height={2} /> */}
            <Text
              sx={{
                color: 'success',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 3,
              }}
            >
              {price}{' '}
              {/* <Text>due {format(datePlusDays(5), 'MMM do, yyy')}</Text> */}
            </Text>
          </Row>
          <Spacer height={3} />
          <Spacer height={2} />
          <View sx={{ height: 1, bg: 'border' }} />
          <Spacer height={2} />
          <Spacer height={3} />
        </View>

        <View>
          <Row>
            <Description
              titleSx={{
                ml: 2,
              }}
              title="Account"
              sx={{ flex: 1, mr: 1 }}
            >
              <Input
                editable={false}
                placeholder="Bank Account"
                sx={{
                  borderRadius: 0,
                  bg: 'muted',
                  pl: 3,
                }}
                // label={'Bank Account'}
                value="Chase Bank"
                containerSx={{
                  // bg: 'muted2',
                  borderRadius: 999,
                  borderWidth: 0,
                }}
                labelSx={{ bg: 'muted2' }}
              />
            </Description>
            <Description
              titleSx={{
                ml: 2,
              }}
              title="ACH"
              sx={{ flex: 1, ml: 1 }}
            >
              <Picker
                items={[{ label: '1-day', value: '1-day' }]}
                disabled
                value="1-day"
                onValueChange={console.log}
                sx={{
                  borderRadius: 999,
                }}
                webPickerStyle={{
                  borderRadius: 999,
                  backgroundColor: colors?.muted,
                  borderWidth: 0,
                  paddingHorizontal: space?.[3],
                }}
              />
            </Description>
          </Row>
          <Spacer />
          <Button
            loading={status === 'loading'}
            iconLeft={{
              name: status === 'success' ? 'checkmark-circle' : 'lock-closed',
              color: 'text',
            }}
            sx={{
              bg: status === 'success' ? 'success' : 'blue',
              borderColor: status === 'success' ? 'success' : 'blue',
              color: 'text',
            }}
            onPress={onPress}
            // isText={false}
          >
            {status === 'success' ? 'Success' : 'Pay'}
            {/* <Text sx={{ fontWeight: 'bold', display: 'inline' }}>
              Pay
              <View
                sx={{
                  display: ['inline', 'inline', 'none'],
                }}
              >
                <Text
                  sx={{
                    // display: ['inline', 'inline', 'none'],
                    fontWeight: 'bold',
                  }}
                >
                  {' '}
                  - {price}
                </Text>
              </View>
            </Text> */}
          </Button>
        </View>
      </Card.Content>
      <Spacer height={[2, 3]} />
    </Card>
  )
}
