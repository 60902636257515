import React from 'react'
import { useDripsyTheme, Image, Text, H4, H5, Row } from 'dripsy'
import BrowserMockup from '@beatgig/design/components/browser-mockup'
import PhoneMockup from '@beatgig/design/components/phone-mockup'
import BookingProgressBar from '../booking-progress-bar'
import mockBooking from '@beatgig/helpers/mock-booking-v2.json'
import { DisplayArtist } from '@beatgig/api-services'
import { useState, useEffect } from 'react'
import { useRef } from 'react'
import Gradient from '@beatgig/design/components/gradient'
import { StyleSheet } from 'react-native'
import Spacer from '@beatgig/design/components/spacer'
import Picker from '@beatgig/components/picker'
import Button from '@beatgig/components/button'
import StarRating from '@beatgig/components/star-rating'
import numeral from 'numeral'
import View from '@beatgig/components/native-view'
import Cloudinary from '@beatgig/helpers/cloudinary'
import defaultBreakpoints from '@beatgig/theme/breakpoints'
import { motion, AnimatePresence } from 'framer-motion'
import Card from '@beatgig/design/components/card'
import NextImage from 'next/image'
import Description from '@beatgig/design/components/description'
import { Video } from 'expo-av'
import Press from '@beatgig/components/press'
import { css } from '@theme-ui/css'
import viewResetStyle from '@beatgig/helpers/view-reset-style'
import { format } from 'date-fns'
import { APP_NAME } from '@beatgig/constants'
import { useInView } from 'react-intersection-observer'
import Input from '@beatgig/design/components/input'
import LinkToArtist from '@beatgig/navigation/links/link-to-artist'
import MarketingBankPayment from './marketing-bank-payment'
import { BookingDisplay, BookingStatus } from '@beatgig/api-services/booking'
// import { animated } from '@react-spring/native'

const _booking = (mockBooking as any) as Omit<BookingDisplay, 'artist'> & {
  artist: DisplayArtist
}

const datePlusDays = (days: number) => {
  const date = new Date(new Date().valueOf())
  date.setDate(date.getDate() + days)
  return date
}

type Props = {
  phone?: React.ReactNode
  booking?: typeof _booking
  review?: string
  reviewAuthor?: string
} & React.ComponentProps<typeof View>

const showPhone = [false, false, true]
const ifPhoneVisible = <V extends any, N extends any>(
  whileVisible: V,
  whileNotVisible: N,
  array: boolean[] = showPhone
) => array.map((visible) => (visible ? whileVisible : whileNotVisible))

const ifIsWide = <V extends any, N extends any>(
  whileVisible: V,
  whileNotVisible: N
) => ifPhoneVisible(whileVisible, whileNotVisible)

const SHOULD_AUTOPLAY = false

export default function HomepageDevices({
  phone,
  sx = {},
  booking = _booking,
  reviewAuthor = 'Kappa Alpha Theta @ University of Florida',
  review = 'Loud Luxury was everything and more...they were super friendly/outgoing and knew exactly how to put on a great show. They engaged with the crowd and made it an unreal atmosphere. Everyone in attendance was ranting and raving for weeks.',
}: Props) {
  const { radii, colors, space } = useDripsyTheme().theme
  //   const { sx = {}, children } = props
  const [current_step_index, setCurrentStepIndex] = useState(0)
  const [rating, setRating] = useState(5)

  const [visibilityRef, isInView] = useInView({
    threshold: 1,
    // initialInView: false,
    initialInView: true,
    skip: !SHOULD_AUTOPLAY,
  })

  const price = numeral(booking.display_prices.buyer).format('$0,0[0]')
  const artistCoverImage = Cloudinary(booking.artist.cover_image, {
    width: defaultBreakpoints[0],
    quality: 50,
  })
  const artistProfileImage = Cloudinary(booking.artist.profile_image, {
    width: 100,
    quality: 50,
  })

  const hasInitiallyLoaded = useRef(false)
  const [showVideo, setShowVideo] = useState(false)
  const htmlVideo = useRef<HTMLVideoElement>(null)
  const autoPlayTimeout = useRef(0)

  useEffect(() => {
    hasInitiallyLoaded.current = true
  }, [current_step_index])

  const autoPlayInterval = useRef(0)

  const hasTakenAction = useRef(false)
  useEffect(() => {
    setReviewHasTyped(false)
  }, [current_step_index])
  useEffect(() => {
    const isInitial = true

    // const setRatingTimeout = 0
    if (!hasTakenAction.current && isInView && SHOULD_AUTOPLAY) {
      autoPlayTimeout.current = setTimeout(() => {
        setCurrentStepIndex((state) => {
          if ((booking.status_information?.total_step_count || 0) < state + 2) {
            clearTimeout(autoPlayTimeout.current)
            return state
          }
          return state + 1
        })
        // }
      }, 5500)
    }

    return () => {
      clearTimeout(autoPlayTimeout.current)
      // clearTimeout(setRatingTimeout)
    }
  }, [
    booking.status_information?.total_step_count,
    current_step_index,
    isInView,
  ])
  const onTakeAction = () => {
    hasTakenAction.current = true
    clearInterval(autoPlayInterval.current)
    clearTimeout(autoPlayTimeout.current)
  }

  const continueToPayment = () => {
    onTakeAction()
    setCurrentStepIndex(1)
  }
  const continueToPerformance = () => {
    onTakeAction()
    setCurrentStepIndex(2)
  }

  const onPressBook = () => {
    onTakeAction()
    setCurrentStepIndex((state) => {
      if ((booking.status_information?.total_step_count || 0) <= state + 1) {
        return 0
      }
      return state + 1
    })
  }

  const [reviewHasTyped, setReviewHasTyped] = useState(false)

  const video = useRef<Video | null>(null)
  const [videoLoaded, setVideoLoaded] = useState(false)

  const goToPaymentButton = (
    <Button
      variant="smallOutlined"
      sx={{
        fontWeight: 'normal',
        fontSize: 2,
        color: 'background',
        borderColor: 'success',
        bg: 'success',
      }}
      onPress={continueToPayment}
    >
      Continue
    </Button>
  )

  const renderVideo = () => {
    if (!showVideo) {
      return null
    }
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          type: 'spring',
        }}
        style={StyleSheet.absoluteFillObject}
        key="video"
      >
        <Press
          sx={{ justifyContent: 'center', ...StyleSheet.absoluteFillObject }}
        >
          <video
            style={{
              ...StyleSheet.absoluteFillObject,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              backgroundColor: 'transparent',
              transitionProperty: 'opacity',
              transitionDuration: '0.25s',
            }}
            autoPlay
            muted
            loop
            playsInline
            ref={htmlVideo}
            onEnded={() => {
              console.log('[video ended]')
              setCurrentStepIndex(0)
            }}
          >
            <source
              src="https://beatgig-prod-files.s3.amazonaws.com/videos/hero-low-res.mp4"
              type="video/mp4"
            />
          </video>
        </Press>
      </motion.div>
    )
  }

  const renderPerformance = () => {
    if (current_step_index !== 2) return null

    return (
      <motion.div
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 20 }}
        transition={{
          type: 'spring',
        }}
        key="performance"
        style={{ flex: 1 }}
      >
        <Card>
          <Card.Content>
            <View
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <View>
                <H5 sx={{ fontSize: 4, mb: 0 }}>Review</H5>
                <Text sx={{ color: 'mutedText' }}>How was the show?</Text>
              </View>
              <StarRating animated rating={rating} onPressStar={setRating} />
            </View>
            <Spacer />
            <View
              sx={{
                p: 3,
                bg: 'muted',
                borderRadius: 3,
                display: 'block',
                color: 'text',
              }}
            >
              <Text sx={{ fontSize: 2 }}>{review}</Text>
              {/* <Typed
                paused={!isInView}
                strings={[
                  'Loud Luxury was everything and more...^1000 they were super friendly/outgoing and knew exactly how to put on a great show.^600 They engaged with the crowd^400 and made it an unreal atmosphere.^500 Everyone in attendance was ranting and raving for weeks.',
                ]}
                textProps={{ sx: { display: 'inline' } }}
                typeSpeed={40}
                onComplete={() => {
                  setReviewHasTyped(true)
                  console.log('[typed-js] complete')
                  // setTimeout(() => {
                  //   setCurrentStepIndex(0)
                  // }, 2000)
                }}
                showCursor={false}
              /> */}
              <LinkToArtist slug={booking.artist.slug} isText>
                <br />
                <Text sx={{ color: 'primary' }}>{reviewAuthor}.</Text>
              </LinkToArtist>
              {/* {reviewHasTyped && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                  <LinkToArtist slug={booking.artist.slug} isText>
                    <Text sx={{ color: 'primary' }}>
                      {'Kappa Alpha Theta @ University of Florida'}.
                    </Text>
                  </LinkToArtist>
                </motion.div>
              )} */}
            </View>
          </Card.Content>
        </Card>
        {renderVideo()}
      </motion.div>
    )
  }

  const renderPayment = () => {
    if (current_step_index !== 1) {
      return null
    }

    return (
      <motion.div
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -20 }}
        transition={{
          type: 'spring',
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        key="payment"
      >
        <MarketingBankPayment price={price} onPress={continueToPerformance} />
        {/* <Card
          sx={{
            alignSelf: 'center',
            maxWidth: 550,
            borderColor: 'blue',
          }}
          // variant="success"
          type="outline"
        >
          <Spacer height={[2, 3]} />
          <Card.Content
            sx={{ alignSelf: 'center', width: '100%', maxWidth: 400 }}
          >
            <View
              sx={{
                display: ['none', null, 'flex'],
              }}
            >
              <Row
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Text
                  sx={{ fontWeight: 'bold', fontSize: 5, textAlign: 'center' }}
                >
                  Invoice
                </Text> 
                <Text
                  sx={{
                    color: 'success',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 3,
                  }}
                >
                  {price}{' '} 
                </Text>
              </Row>
              <Spacer height={3} />
              <Spacer height={2} />
              <View sx={{ height: 1, bg: 'border' }} />
              <Spacer height={2} />
              <Spacer height={3} />
            </View>

            <View>
              <Row>
                <Description
                  titleSx={{
                    ml: 2,
                  }}
                  title="Account"
                  sx={{ flex: 1, mr: 1 }}
                >
                  <Input
                    editable={false}
                    placeholder="Bank Account"
                    sx={{
                      borderRadius: 0,
                      bg: 'muted',
                      pl: 3,
                    }}
                    // label={'Bank Account'}
                    value="Chase Bank"
                    containerSx={{
                      // bg: 'muted2',
                      borderRadius: 999,
                      borderWidth: 0,
                    }}
                    labelSx={{ bg: 'muted2' }}
                  />
                </Description>
                <Description
                  titleSx={{
                    ml: 2,
                  }}
                  title="ACH"
                  sx={{ flex: 1, ml: 1 }}
                >
                  <Picker
                    items={[{ label: '1-day', value: '1-day' }]}
                    disabled
                    value="1-day"
                    onValueChange={console.log}
                    sx={{
                      borderRadius: 999,
                    }}
                    webPickerStyle={{
                      borderRadius: 999,
                      backgroundColor: colors?.muted,
                      borderWidth: 0,
                      paddingHorizontal: space?.[3],
                    }}
                  />
                </Description>
              </Row>
              <Spacer />
              <Button
                iconLeft={{ name: 'lock-closed', color: 'text' }}
                sx={{ bg: 'blue', borderColor: 'blue', color: 'text' }}
                onPress={continueToPerformance}
                isText={false}
              >
                <Text sx={{ fontWeight: 'bold', display: 'inline' }}>
                  Pay
                  <View
                    sx={{
                      display: ['inline', 'inline', 'none'],
                    }}
                  >
                    <Text
                      sx={{
                        // display: ['inline', 'inline', 'none'],
                        fontWeight: 'bold',
                      }}
                    >
                      {' '}
                      - {price}
                    </Text>
                  </View>
                </Text>
              </Button>
            </View>
          </Card.Content>
          <Spacer height={[2, 3]} />
        </Card> */}
        {/* <Spacer />
        <Card>
          <Card.Content>
            <View
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <View>
                <Text sx={{ fontWeight: 'bold', fontSize: 3 }}>{price}</Text>
                <Text sx={{ color: 'mutedText' }}>
                  ACH Transfer (Chase Bank)
                </Text>
              </View>
              <Button
                variant="smallOutlined"
                sx={{
                  alignSelf: 'flex-start',
                  borderColor: 'success',
                  color: 'background',
                  fontWeight: 'normal',
                  fontSize: 3,
                  bg: 'success',
                }}
                onPress={continueToPerformance}
              >
                Pay
              </Button>
            </View>
          </Card.Content>
        </Card>
        <Spacer />
        <Text sx={{ color: 'mutedText' }}>(This is an example payment.)</Text> */}
      </motion.div>
    )
  }
  const renderNegotiating = () => {
    if (current_step_index) {
      return null
    }
    return (
      <motion.div
        animate={{ opacity: 1, x: 0 }}
        initial={
          hasInitiallyLoaded.current && {
            opacity: 0,
            x: 20,
          }
        }
        exit={{ opacity: 0, x: -20 }}
        transition={{
          type: 'spring',
        }}
        key="negotiating"
      >
        <Card variant="success" type="outline">
          <Card.Content sx={{ flexDirection: 'row', alignItems: 'center' }}>
            {!!artistCoverImage && (
              <Image
                source={{ uri: artistCoverImage }}
                sx={{ size: 30, borderRadius: 'rounded', mr: 2 }}
              />
            )}
            <View sx={{ flex: 1 }}>
              <Text sx={{ fontWeight: 'bold', color: 'text' }}>
                {booking.artist.name} accepted your offer!
                {/* <Text sx={{ fontWeight: 'bold', color: 'text' }}>{price}</Text>! */}
              </Text>
              <Text sx={{ color: 'success' }}>{price}</Text>
            </View>
            <View sx={{ display: ifIsWide('flex', 'none') }}>
              {goToPaymentButton}
            </View>
          </Card.Content>
        </Card>
        <Spacer height={3} />
        <View
          sx={{
            alignItems: 'flex-start',
            display: ifIsWide('none', 'flex'),
          }}
        >
          {goToPaymentButton}
        </View>
        <View sx={{ display: ifPhoneVisible('flex', 'none') }}>
          <Card>
            <Card.Content sx={{ flexDirection: 'row' }}>
              <Description sx={{ mr: 2, flex: 1 }} title="Venue Location">
                <Text sx={{ fontWeight: '500' }}>{booking.venue_name}</Text>
                <Text sx={{ color: 'mutedText' }}>
                  {booking.venue_location.street_address}
                </Text>
                <Text sx={{ color: 'mutedText' }}>
                  {booking.venue_location.city},{' '}
                  {booking.venue_location.state_abbr}{' '}
                  {booking.venue_location.postal_code}
                </Text>
              </Description>
              <Description sx={{ ml: 2, flex: 1 }} title="Concert Details">
                <Text sx={{ fontWeight: '500' }}>
                  {format(datePlusDays(10), 'MMMM do, yyy')}
                </Text>
                {booking.start_time && (
                  <Text sx={{ color: 'mutedText' }}>
                    {format(new Date(booking.start_time), 'h:mm aaa')}
                  </Text>
                )}
                <Text sx={{ color: 'mutedText' }}>{booking.billing}</Text>
              </Description>
            </Card.Content>
          </Card>
          <Spacer />
          <Card>
            <Card.Content>
              <Description title="Production">
                <Text>{`${APP_NAME} will take care of stage, lights & sound.`}</Text>
              </Description>
            </Card.Content>
          </Card>
        </View>
      </motion.div>
    )
  }

  // const indexRef = useRef(current_step_index)
  // useEffect(() => {
  //   indexRef.current = current_step_index
  // })

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     // setCurrentStepIndex((state) => {
  //     //   if (state + 1 >= booking.status_information.all_steps.length) {
  //     //     clearInterval(interval)
  //     //     return state
  //     //   }
  //     //   return state + 1
  //     // })
  //   }, 4000)
  //   // const timeout = setTimeout(() => setCurrentStepIndex(1), 4000)

  //   return () => {
  //     clearInterval(interval)
  //     // clearTimeout(timeout)
  //   }
  // }, [])

  return (
    <View
      sx={{
        flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        pb: 4,
        transform: [null, 'scale(.9)'],
        ...sx,
      }}
      // importantForAccessibility="no-hide-descendants"
      ref={visibilityRef as any}
    >
      <View sx={{ px: [2, null, 0] }}>
        <BrowserMockup
          onPressButton={() => setCurrentStepIndex(0)}
          sx={{
            width: (theme) => [
              '100%',
              null,
              // '150%',
              `calc(100% - ${theme.space[3]}px)`,
            ],
          }}
        >
          <View sx={{ minHeight: [300, null, 530], bg: 'muted' }}>
            <motion.div style={{ ...(viewResetStyle as any), flex: 1 }}>
              {/* <Gradient
                // colors={['#194189', '#8738a7']}
                colors={['#194189', 'secondary']}
                sx={{ ...StyleSheet.absoluteFillObject, zIndex: -1 }}
              /> */}
              <View
                sx={{
                  ml: (theme) => ifPhoneVisible(theme.space[6] * 1.6, 0),
                  flex: 1,
                  px: 3,
                  py: 2,
                }}
              >
                <Spacer />
                <H4 sx={{ fontSize: [6, 7] }}>
                  Your {booking.artist.name} Booking
                </H4>
                <Spacer />
                <BookingProgressBar
                  booking={{
                    ...booking,
                    status_information: booking.status_information && {
                      ...booking.status_information,
                      current_step_index,
                    },
                    status: booking.status_information?.status_bar[
                      current_step_index
                    ].status as BookingStatus,
                  }}
                  shouldFillOnLastStep
                  labelSx={{
                    // fontSize: [2, 3],
                    mt: 2,
                  }}
                  barProps={{
                    height: 10,
                  }}
                />
                {/* <Spacer height={3} />
              <Card>
                <Card.Content>
                  <Description title="Location">807 Sycamore Pl.</Description>
                </Card.Content>
              </Card> */}
                <Spacer height={ifPhoneVisible(4, 3)} />
                <AnimatePresence exitBeforeEnter>
                  {renderNegotiating()}
                  {renderPayment()}
                  {renderPerformance()}
                </AnimatePresence>
              </View>
            </motion.div>
          </View>
        </BrowserMockup>
        <PhoneMockup
          sx={{
            position: 'absolute',
            left: -60,
            top: 60,
            // display: ['none', null, 'flex'],
            display: ifPhoneVisible('flex', 'none'),
            transform: [
              ...ifPhoneVisible('scale(.8)', 'none'),
              // null,
              'scale(.9)',
              // 'scale(1)',
            ],
            transformOrigin: 'top right',
            boxShadow: '0 0 20px 6px #00000020',
          }}
        >
          <View sx={{ flex: 1, bg: 'black', justifyContent: 'space-between' }}>
            <View>
              <View sx={{ width: '100%', height: 0, pb: '80%' }}>
                <Image
                  source={{
                    uri: artistCoverImage,
                  }}
                  sx={{
                    ...StyleSheet.absoluteFillObject,
                    //   borderRadius: 'rounded',
                  }}
                />
              </View>
              <Spacer />
              <H4 sx={{ textAlign: 'center', mb: 0 }}>{booking.artist.name}</H4>
              <Spacer />
              <Text
                sx={{
                  color: 'success',
                  textAlign: 'center',
                  fontSize: 5,
                  fontWeight: 'bold',
                }}
              >
                {price}
              </Text>
              <Spacer />
              <View sx={{ alignItems: 'center' }}>
                <StarRating rating={5} reviewCount={2} />
              </View>
              <Button onPress={onPressBook} sx={{ m: 3 }}>
                Book
              </Button>
              {/* <AnimatePresence exitBeforeEnter>
                {current_step_index === 0 && (
                  <motion.div
                    layout
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0, y: -5 }}
                    transition={{
                      duration: '.5s',
                    }}
                    key="book-button"
                    initial={false}
                  >
                    <Button
                      onPress={() => setCurrentStepIndex(1)}
                      sx={{ m: 3 }}
                    >
                      Book
                    </Button>
                  </motion.div>
                )}
              </AnimatePresence> */}
            </View>
          </View>
        </PhoneMockup>
      </View>
    </View>
  )
}
