export { default } from '@beatgig/next-pages/home'

// import React from 'react'
// import DiscoverScreen from '@beatgig/screens/discover-screen-fast'
// import * as Home from '@beatgig/next-pages/new-home'
// import SEO from '@beatgig/components/seo'
// import { createStackNavigator } from '@react-navigation/stack'

// const { Navigator, Screen } = createStackNavigator()

// function DiscoverPageFast(props: React.ComponentProps<typeof DiscoverScreen>) {
//   return (
//     <>
//       <SEO />
//       <Navigator
//         screenOptions={{
//           cardStyle: {
//             flex: 1,
//           },
//           headerShown: false,
//         }}
//       >
//         <Screen name="Discover">{() => <DiscoverScreen {...props} />}</Screen>
//       </Navigator>
//     </>
//   )
// }

// export default React.memo(DiscoverPageFast)

// export const getStaticProps = Home.getStaticProps
