import { DiscoverService } from '@beatgig/api-services/discover'

export class DiscoverPageApi {
  static get() {
    return DiscoverService.getDiscoverPage()
  }

  static swrKey({ uid }: { uid: string | null }) {
    return [uid, 'discover-page']
  }
}

export const DiscoverPage = DiscoverPageApi
