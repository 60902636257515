import useGet from './use-get'
import { DiscoverPageApi } from '@beatgig/api/discover-page'
import { User } from '@beatgig/api/user'
import { UseGetConfig } from './use-get.types'
import useStable from '@beatgig/design/hooks/use-stable'
import useAuth from '@beatgig/auth-hooks/use-auth'

export default function useDiscoverPage({
  initialData,
  ...options
}: UseGetConfig<typeof DiscoverPageApi['get']> = {}) {
  // const { data } = useMyAccount()
  const { user } = useAuth()
  const stableInitialData = useStable(initialData)
  const swr = useGet(
    () => {
      return DiscoverPageApi.swrKey({
        // uid: User.hasOnboarded(data) ? data.id ?? null : null,
        uid: user?.uid ?? null,
      })
    },
    async (uid) => {
      // return DiscoverPageApi.get()
      // return discover

      if (uid) {
        console.log('[use-discover-page] will fetch authd', { uid })
        const discover = await DiscoverPageApi.get()

        return discover
      }
      console.log('[use-discover-page] not authd', { uid })
      // if we haven't authed yet, let's check if there's a user in the cache
      const cachedUser = await User.getFromAsyncStorage()

      // if we know there's a cached user, but it isn't loaded yet, wait
      if (User.hasOnboarded(cachedUser)) {
        return { artist_rows: [] }
      }

      // if (User.hasOnboarded(cachedUser)) {
      //   console.log(
      //     '[use-discover-page] not authd, has cached user',
      //     cachedUser
      //   )
      //   const {
      //     default_state_abbr,
      //     other_state_response,
      //     user_type,
      //     id,
      //   } = cachedUser
      //   const state = default_state_abbr || other_state_response
      //   if (state && user_type && id) {
      //     console.log(
      //       '[use-discover-page] not authd, discover from cached user',
      //       cachedUser
      //     )

      //     const discover = await DiscoverPageApi.getFromCachedUser({
      //       state,
      //       userType: user_type,
      //     })
      //     console.log(
      //       '[use-discover-page] not authd, discover from cached user',
      //       cachedUser
      //     )

      //     console.log('[use-discover-page] will set from cache', {
      //       key: DiscoverPageApi.swrKey({
      //         uid: id,
      //       }),
      //       discover,
      //     })
      //     // let's cache for this user now too
      //     cache.set(
      //       DiscoverPageApi.swrKey({
      //         uid: id,
      //       }),
      //       discover
      //     )

      //     return discover
      //   }
      // } else if (stableInitialData.current) {
      //   // if we have an unauthed fallback...
      //   cache.set(
      //     DiscoverPageApi.swrKey({
      //       uid: null,
      //     }),
      //     stableInitialData.current
      //   )
      // }

      const discover = await DiscoverPageApi.get()

      return discover
    },
    options
  )

  // for native, where there is no initial data
  // const fallback = cache.get(DiscoverPage.swrKey({ uid: null }))

  // return swr
  return Object.assign({}, swr, {
    data: swr.data,
  })
}
