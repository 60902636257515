import { ConfigInterface } from 'swr'
import { User } from '@beatgig/api/user'
import { Unpromisify } from '@beatgig/helpers/types'
import useGet from './use-get'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'

type Response = Unpromisify<ReturnType<typeof User['getMyLikedArtistIds']>>

export default function useGetMyLikedArtistIds(
  options: ConfigInterface<Response> = {}
) {
  const { data } = useMyAccount()
  return useGet(
    () => {
      if (!User.hasOnboarded(data) || !data?.id) return null
      return User.myLikedArtistIdsSWRKey(data?.id)
    },
    () => User.getMyLikedArtistIds(),
    {
      // dedupingInterval: 7000,
      ...options,
    }
  )
}
