import { User } from '@beatgig/api/user'
import useGet from './use-get'
import { UseGetConfig } from './use-get.types'

type Getter = typeof User['getPublicUser']
type Key = typeof User['publicUserSWRKey']

export default function usePublicUser(
  keyProps: Parameters<Key>[0] | null,
  config?: UseGetConfig<Getter>
) {
  return useGet(
    () => {
      if (!keyProps?.slug) return null

      const key = User.publicUserSWRKey(keyProps)

      return key
    },
    (...[slug]: ReturnType<Key>) => {
      return User.getPublicUser({
        slug,
      })
    },
    config
  )
}
