/** @jsx jsx */
import viewResetStyle from '@beatgig/helpers/view-reset-style'
import { ComponentProps } from 'react'
import { jsx } from '@theme-ui/core'
import { forwardRef } from 'react'
import type { Text as TextProps } from 'dripsy'

const NativeText = forwardRef(function NativeText(
  { sx = {}, variant = 'body', ...props }: ComponentProps<typeof TextProps>,
  ref: any
) {
  return (
    <span
      {...(props as any)}
      ref={ref}
      sx={{
        ...viewResetStyle,
        variant: `text.${variant}`,
        ...sx,
        display: 'inline-block',
      }}
    />
  )
})

export default NativeText
