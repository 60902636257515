import { useAsyncCallback, UseAsyncCallbackOptions } from 'react-async-hook'
import { Sentry } from '@beatgig/helpers/sentry'
import { Unpromisify } from '@beatgig/helpers/types'
import useIntercom from '@beatgig/hooks/use-intercom'
import { useCallback } from 'react'
import { ServerError } from '@beatgig/api-services'

export type UsePostOptions<
  Returns extends (...args: any) => any
> = UseAsyncCallbackOptions<Unpromisify<ReturnType<Returns>>>

export default function usePost<R = unknown, Args extends any[] = any[]>(
  asyncFunction: (...args: Args) => Promise<R> | R,
  options?: UseAsyncCallbackOptions<R>
) {
  const { set, result, loading, status, ...callback } = useAsyncCallback<
    R,
    Args
  >(async (...args) => {
    let value: R
    try {
      value = await asyncFunction(...args)
    } catch (e) {
      {
        Sentry.captureEvent({
          message: `POST error.`,
          extra: {
            error: e,
          },
        })
        throw e
      }
      throw e
    }
    return value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, options)

  const setError = useCallback(
    (error: ServerError) => {
      set({
        error,
        result,
        loading,
        status,
      })
    },
    [loading, result, set, status]
  )

  return { ...callback, set, result, loading, setError }
}
