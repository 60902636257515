import React from 'react'
import View from '@beatgig/components/native-view'
import { Platform } from 'react-native'
import { DripsyTheme } from '@beatgig/theme'

type Props = {
  children?: React.ReactNode
  width?: number
  height?: number
  borderColor?: keyof DripsyTheme['colors']
} & React.ComponentProps<typeof View>

export default function PhoneMockup(props: Props) {
  const { sx = {}, children, width = 264, borderColor = 'text' } = props

  return (
    <View
      sx={{
        width,
        height: width * 2.01,
        borderWidth: (theme) => theme.space?.[2],
        borderColor,
        // bg: 'text',
        boxShadow: Platform.select({
          web:
            '0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), inset 0 -2px 6px 0 rgba(10,37,64,.35)',
          default: undefined,
        }),
        borderRadius: 36,
        overflow: 'hidden',
        ...sx,
      }}
    >
      {children}
    </View>
  )
}
