import { useCalc, useResponsive } from '@beatgig/hooks/use-responsive'
import useStyleArray from '@beatgig/hooks/use-style-array'
import { useMemo } from 'react'
import { useWindowDimensions } from 'react-native'
import { useResponsiveValue } from 'dripsy'

export default function useTalentCardDimensions() {
  const { vw, vh } = useResponsive()
  const { divide, calc } = useCalc()
  //   const { width = 0 } = useDimensions().window
  const dimensions = useWindowDimensions()

  // const width = 300
  // const { width } = Dimensions.get('window')

  //   const itemWidth = width / 5
  const aspectRatioOld = 286 / 159
  const aspectRatio = 110 / 160
  //   const itemHeight = itemWidth / aspectRatio

  //   const finalWidth = [110 * 1.2, null, null, itemWidth]
  //   const height = [160 * 1.2, null, null, itemHeight]

  const itemWidth = vw(20)
  const rawItemWidth = dimensions.width / 5

  const itemHeight = calc(divide(itemWidth, `${aspectRatio}`))
  const rawItemHeight = rawItemWidth / aspectRatio

  const width = useMemo(() => [110 * 1.2, null, null, itemWidth], [itemWidth])
  const height = useMemo(() => [160 * 1.2, null, null, itemHeight], [
    itemHeight,
  ])

  const { map } = useStyleArray()

  const rawHeight = map(height, (heightItem) => {
    if (typeof heightItem === 'number') {
      return heightItem
    }
    return rawItemHeight
  })

  const rawWidth = useMemo(
    () =>
      map(width, (widthItem) => {
        if (typeof widthItem === 'number') {
          return widthItem
        }
        return rawItemWidth
      }),
    [map, rawItemWidth, width]
  )

  const currentBreakpointRawWidth = useResponsiveValue(rawWidth)

  return {
    width,
    height,
    rawHeight,
    rawWidth,
    currentBreakpointRawWidth,
  }
}
