import React from 'react'
import { Text } from 'dripsy'
import { APP_URL } from '@beatgig/constants'
import BlurView from '@beatgig/components/blur'
import { StyleSheet } from 'react-native'
import View from '@beatgig/components/native-view'
import Press from '@beatgig/components/press'

type Props = {
  children: React.ReactNode
  url?: string
  border?: boolean
  onPressButton?: (button: 'close' | 'minify' | 'expand') => void
} & React.ComponentProps<typeof View>

export default function BrowserMockup(props: Props) {
  const {
    sx = {},
    children,
    url = APP_URL,
    border = true,
    onPressButton,
  } = props

  const renderHeader = () => {
    const circle = (bg: string) => (
      <Press
        key={bg}
        sx={{
          height: 11,
          width: 11,
          bg,
          ml: 2,
          borderRadius: 'rounded',
        }}
        onPress={() => onPressButton?.('close')}
      />
    )
    return (
      <View sx={{ height: 40, flexDirection: 'row' }}>
        {/* <BlurView intensity={100} tint="dark" style={StyleSheet.absoluteFill} /> */}
        <View
          sx={{
            // position: 'absolute',
            // left: 0,
            // top: 0,
            // bottom: 0,
            flex: 1,
            alignItems: 'center',
            flexDirection: 'row',
            flexBasis: '0%',
          }}
        >
          {['error', 'warning', 'success'].map(circle)}
        </View>
        <View
          sx={{
            alignSelf: 'center',
            // maxWidth: 300,
            mx: 4,
            flex: 4,
            flexBasis: '0%',
            alignItems: 'center',
            height: '70%',
          }}
        >
          <View
            sx={{
              width: 300,
              maxWidth: '100%',
              bg: 'muted2',
              borderRadius: 3,
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Text>{url}</Text>
          </View>
        </View>
        <View sx={{ flex: 1, flexBasis: '0%' }} />
      </View>
    )
  }

  return (
    <View
      sx={{
        borderRadius: 3,
        overflow: 'hidden',
        borderWidth: border ? 1 : 0,
        borderColor: 'border',
        ...sx,
      }}
    >
      {renderHeader()}

      <View sx={{ bg: 'background' }}>{children}</View>
    </View>
  )
}
