import React from 'react'
import { View } from 'dripsy'
import useWebHeaderHeight from './use-web-header-height'
import Sizer from '../sizer'

type Props = {
  /**
   * Default: `background`
   */
  bg?: string
}

export default function WebHeaderSpacer(props: Props) {
  const { bg = 'background' } = props
  const { height } = useWebHeaderHeight()
  return (
    <Sizer
      {...{
        height,
        bg,
        pointerEvents: 'none',
      }}
    />
  )
}
