/** @jsx jsx */
import { jsx } from '@theme-ui/core'
import { HoverPulseProps } from './types'

export default function HoverPulse({
  scaleTo = 1.05,
  hoveredZIndex,
  ...props
}: HoverPulseProps) {
  const { sx = {}, children } = props

  return (
    <div
      sx={{
        transform: 'scale(1)',
        transitionProperty: 'transform',
        transitionTimingFunction: 'ease',
        transitionDuration: '0.25s',
        '&:hover, &:focus': {
          transform: `scale(${scaleTo})`,
          zIndex: hoveredZIndex,
        },
        // RNW reset
        alignItems: 'stretch',
        border: '0 solid black',
        boxSizing: 'border-box',
        display: 'flex',
        flexBasis: 'auto',
        flexDirection: 'column',
        flexShrink: 0,
        margin: 0,
        minHeight: 0,
        minWidth: 0,
        padding: 0,
        position: 'relative',
        zIndex: 0,
        ...sx,
      }}
    >
      {children}
    </div>
  )
}
